import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../components/footer";
import Header from "../components/header";

import { Link, useParams } from 'react-router-dom';

function SingleEvent() {

  // Fetch Single Event data
  const { id } = useParams();
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/event-posts/${id}?_embed`);
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]);

  // at the bottom testimonials video
  const [feedPlaying, setfeedPlaying] = useState(false);
  const handleClickfeed = (videoElement) => {
    const overlayOn = document.querySelector(".overlay-on-feed");
    if (videoElement.paused || videoElement.ended) {
      videoElement.play();
      setfeedPlaying(true);
      overlayOn.classList.remove("bg-[#000000b2]");
    } else {
      videoElement.pause();
      setfeedPlaying(false);
      overlayOn.classList.add("bg-[#000000b2]");
    }
  };

  // sliderFeed slider
  const sliderFeed = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // Picture Slider
  const sliderPicture = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    speed: 500,
    autoplay: false,
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 769,
        settings: {
          centerMode: true,
          slidesToShow: 3,
        },
      },
    ],
  };

   return (
    <>
      {/* Header */}
      <header className="fixed inset-x-0 top-0 z-[9999] bg-darkGreen py-3">
        <Header />
      </header>

      {events ? (
      <>
        <section className="py-8 md:py-14 xl:py-28 bg-fadeGreen">
          <div className="container xxl:max-larger:max-w-[1220px] pt-20">
          <Link to="/events" onClick={() => { window.scroll(0, 0); }}
              className="font-AvenirLight leading-normal text-white inline-flex items-center"
            >
              <svg
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
              </svg>
              <span className="ml-4">Back To Events</span>
            </Link>

            {events.title && events.title.rendered && (
              <h2 className="text-left md:text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize mt-10 md:mt-20 xl:mt-28 xl:w-[90%] mx-auto">
                {events.title.rendered}
              </h2>
            )}
            {events.content && events.content.rendered && (
              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white text-left md:text-center mt-6 xl:max-w-[96%] larger:max-w-[72%] mx-auto editor_text">
                <span dangerouslySetInnerHTML={{ __html: events.content.rendered }} />
              </p>
            )}
          </div>
        </section>

        {/* Sponsors */}
        {events.acf && events.acf.event_sponsors && (
          <section className="py-8 md:py-28 xl:py-28">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-left md:text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize xl:w-[90%] mx-auto">
                {events.acf.event_sponsors.heading}
              </h2>
              {events.acf.event_sponsors.sponsors ? (
                <>
                  {events.acf.event_sponsors.sponsors.length > 0 ? (
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-10 md:gap-14 xl:gap-24 xl:max-w-[75%] mx-auto my-8 md:my-20 xl:my-28">
                      {events.acf.event_sponsors.sponsors.map((slide, index) => (
                        <div key={index}>
                          <img
                            src={slide.logo}
                            alt="sponser of events"
                            className="w-full object-contain h-[80px]"
                          />
                        </div>
                      ))}
                    </div>
                  ) : (<></>)}
                </>
              ) : (<></>)}
              {events.acf.event_sponsors.link ? (
                <div className="text-center mt-4 lg:mt-10">
                  <a
                    href={events.acf.event_sponsors.link.url}
                    className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen inline-block group"
                  >
                    {events.acf.event_sponsors.link.title}
                    <span className="ml-4 group-hover: animate-wiggle inline-block">
                      <img
                        src="/images/darkest-right-arrow.png"
                        alt="darkest-right-arrow"
                        className="w-full max-w-[14px] md:max-w-[20px] object-contain"
                      />
                    </span>
                  </a>
                </div>
              ) : (<></>)}
            </div>
          </section>
        )}

        {/* Event in Pictures */}
        {events.acf && events.acf.event_in_pictures && (
          <section className="pb-8">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-left md:text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize xl:w-[90%] mx-auto">
                {events.acf.event_in_pictures.heading}
              </h2>
              {/* slider */}    
              {events.acf.event_in_pictures.pictures? (
                <div className="picture-slider">
                  {events.acf.event_in_pictures.pictures.length > 0 ? (
                    <Slider {...sliderPicture}>
                      {events.acf.event_in_pictures.pictures.map((slide, index) => (
                        <div className="item" key={index}>
                            <div className="content">
                              <img className='2xl:h-[480px] xl:h-[380px] lg:h-[300px] md:h-[380px] h-[210px] object-cover' src={slide.picture} alt="" />
                            </div>
                        </div>
                      ))}
                    </Slider>
                  ) : (<></>)}
                </div>
              ) : (<></>)}
            </div>
          </section>
        )}

        {/* Event Experience Testimonials */}
        {events.acf && events.acf.event_experience_testimonials && (
          <section className="py-8 md:py-14 xl:py-28 mb-10 lg:mb-14">
          <div className="container xxl:max-larger:max-w-[1220px]">
            <h2 className="text-left md:text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize xl:w-[90%] mx-auto">
              {events.acf.event_experience_testimonials.heading}
            </h2>

            {events.acf.event_experience_testimonials.slide ? (
              <div className="slider-feed mx-[-12px] mt-4 md:mt-14 xl:mt-20">
                {events.acf.event_experience_testimonials.slide.length > 0 ? (
                  <Slider {...sliderFeed}>
                    {events.acf.event_experience_testimonials.slide.map((slide, index) => (
                      <div className="px-3 h-full" key={index}>
                        <div className="grid lg:grid-cols-2 items-center gap-14">
                          <div className="border-2 border-lightGray p-2 md:p-4">
                            <div className="containedfeed relative">
                              <video
                                loop poster={slide.video_thumbnail}
                                className={`video-played-feed-${index} w-full h-[340px] object-cover border border-lightGray`}
                              >
                                <source src={slide.video_url} />
                              </video>

                              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center overlay-on-feed">
                                <img
                                  src={
                                    feedPlaying
                                      ? "/images/light-pause-icon.webp"
                                      : "/images/light-play-icon.webp"
                                  }
                                  alt="light-play-icon"
                                  className="circle-play w-full max-w-[40px] md:max-w-[80px] cursor-pointer object-contain duration-300"
                                  onClick={() =>
                                    handleClickfeed(
                                      document.querySelector(
                                        `.video-played-feed-${index}`
                                      )
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <img
                              src="/images/revert-inverted-comma.png"
                              alt="inverted commaa"
                              className="w-full max-w-[40px] md:max-w-[60px] object-contain"
                            />

                            <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen mt-10 lg:mt-4 xxl:mt-14">
                              <span dangerouslySetInnerHTML={{ __html: slide.text }} />
                            </p>

                            <div className="flex items-start gap-3 mt-10 lg:mt-4 xxl:mt-14">
                              <div className="font-AvenirLight text-xs md:text-sm font-bold text-fadeGreen leading-2 capitalize">
                                _
                              </div>
                              <div>
                                <h2 className="font-AvenirLight text-xs md:text-sm font-bold text-fadeGreen leading-normal capitalize">
                                  {slide.user}
                                </h2>
                                <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen">
                                  {slide.user_position}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (<></>)}
              </div>
            ) : (<></>)}
            </div>
          </section>
        )}

      </>
      ) : ''}

      {/* Footer */}
      <Footer />
    </>
   );
}

export default SingleEvent
