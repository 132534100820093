import { useState } from 'react'
import { useLocation, Link } from 'react-router-dom';
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
const navigation = [
    { name: 'Home', to: '/' },
    { name: 'The Campus', to: '/campus' },
    { name: 'Location Advantage', to: '/connectivity' },
    { name: 'Tech Forward', to: '/technology' },
    { name: 'Beyond Work', to: '/beyond-work' },
    { name: 'Inspirational Spaces', to: '/inspirational-spaces' },
    { name: 'Sustainability & Wellness', to: '/sustainability-wellness' },
    { name: 'Events', to: '/events' },
    { name: 'Media Centre', to: '/media-centre' },
    { name: 'Get in touch', to: '/get-in-touch' },
  ]
export default function Header(){
  const location = useLocation();
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
    return(
      <div>
       
        {/* <nav className="flex items-center justify-between p-4 lg:px-8 lg:w-[95%] mx-auto" aria-label="Global"> */}
        <div className="container xxl:max-larger:max-w-[1220px]">
          <nav className="flex items-center justify-between xxl:py-5 xl:py-4 sm:py-3 xs:py-3" aria-label="Global">
            <div className="flex lg:flex-1">
              <Link to="/" className="-m-1.5 p-1.5" onClick={() => { window.scroll(0, 0); }}>
                <span className="sr-only">Ecoworld</span>
                {/* <span className='text-white uppercase tracking-[0.35em] font-[500]'>Ecoworld</span> */}
                <img
                  className="h-6 md:h-8 w-auto"
                  src="/images/logo-new.png"
                  alt=""
                />
              </Link>
            </div>
            <div className="flex">
              <button
                type="button"
                className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 duration-3000"
                onClick={() => setMobileMenuOpen(true)}
              >
                <span className="sr-only">Open main menu</span>
                <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
              </button>
            </div>
          </nav>
        </div>
        <Dialog as="div" className="" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-50" />
          {/* <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"> */}
          <Dialog.Panel className="fixed inset-y-0 right-0 z-[999999] w-full overflow-y-auto bg-white px-6 py-6 sm:ring-1 sm:ring-gray-900/10 text-center">
            <div className="flex items-center justify-between">
              <b className="-m-1.5 p-1.5">
                <span className="sr-only">Your Company</span>
                {/* <img
                  className="h-8 w-auto"
                  src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                  alt=""
                /> */}
              </b>
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="mt-6 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-2 py-6">
                  {/* {navigation.map((item) => (
                    <Link key={item.name} to={item.to} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-fadeGreen hover:text-darkGreen" onClick={() => { window.scroll(0, 0);}}>{item.name}</Link>
                  ))} */}
                  {navigation.map((item) => (
                    <Link
                      key={item.name}
                      to={item.to}
                      className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-fadeGreen hover:text-darkGreen font-SangBleuKingdomTrial ${
                        location.pathname === item.to ? 'active-link' : ''
                      }`}
                      onClick={() => {
                        window.scroll(0, 0);
                      }}
                    >
                      {item.name}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </Dialog.Panel>
        </Dialog> 
      
      </div>
    )
}