import React, { useEffect, useState } from 'react';
import Banner from "../components/banner";
import Campus from '../components/campus';
import Footer from '../components/footer';
const CampusPage = () => {

    // Fetch Banner Data
    const [bannerData, setbannerData] = useState(null);
    useEffect(() => {
        const fetchbannerData = async () => {
        try {
            const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/238');
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setbannerData(data.acf);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchbannerData();
    }, []);

    return(
       
        <div className='bg-white campus-page'>
             {bannerData ? (
                <Banner head={bannerData.banner.left_heading} leftText={<span dangerouslySetInnerHTML={{ __html: bannerData.banner.left_text }} />}
                rightText={<span dangerouslySetInnerHTML={{ __html: bannerData.banner.right_text }} />}
                backgroundImage={bannerData.banner.background_image} bgColor="bg-[#e6e7e8]" headColor="text-darkGreen" textColor="" 
                secWidth="xxl:w-[78%] 2xl:w-[81%] xxxl:w-[73%] xl:w-[82%] medium:w-[86%]"
            />
            ) : (<></>)}
            
            <Campus />
            
            <Footer />
        </div>
        
    )
}
export default CampusPage;