import React, { useEffect, useState } from 'react';
import Banner from '../components/banner'
import Footer from '../components/footer';

const TechForward = () => {

    // Fetch Page Data
   const [pageData, setpageData] = useState(null);
   useEffect(() => {
       const fetchpageData = async () => {
       try {
           const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/155');
           if (!response.ok) {
           throw new Error('Network response was not ok');
           }

           const data = await response.json();
           setpageData(data.acf);
       } catch (error) {
           console.error('Error fetching data:', error);
       }
       };

       fetchpageData();
   }, []);

    return (
        <div className='technology-main'>
            {pageData ? (
                <>
                    {/* Banner */}
                    <Banner head={pageData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: pageData.banner.right_text }} />}
                    backgroundImage={pageData.banner.background_image} 
                    bgColor="bg-darkGreen" headColor="text-white" textColor="text-white" 
                    headWidth="w-[100%] larger:w-[17.5%] xxl:w-[19%] 2xl:w-[18%] xxxl:w-[15%] medium:w-[20.97%]"
                    secWidth="w-[90%] xxxl:w-[65%] larger:w-[66.5%] xxl:w-[72%] xl:w-[76%] medium:w-[81%]" />

                    {/* Image Text Grid */}
                    {pageData.image_text_grid.length > 0 ? (
                        <section className="py-4 md:py-10 xl:pt-24 larger:pt-32 vid bg-white">
                            {pageData.image_text_grid.map((grid, index) => (
                                <div className="container xxl:max-larger:max-w-[1220px]" key={index}>
                                    <h2 className={`${index > 0 ? 'pt-20 xl:pt-24 larger:pt-32' : ''} text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-lg lg:text-xxl font-medium text-fadeGreen leading-normal capitalize`}>
                                        {grid.heading}
                                    </h2>

                                    {index % 2 === 0 ? (

                                    <div className="border border-black p-2 md:p-4 xl:p-6 mt-4 lg:mt-10 xxl:mt-20 md:flex items-center justify-between seamSec">
                                        <div className="md:w-3/5">
                                        <img
                                            src={grid.image}
                                            alt="Command-Centre"
                                            className="w-full h-full object-contain"
                                        />
                                        </div>

                                        <div className="md:w-[37%] larger:pr-20 mt-4">
                                        <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                            {grid.sub_heading}
                                        </h4>
                                        <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen mt-1">
                                            <span dangerouslySetInnerHTML={{ __html: grid.content }} />
                                        </p>
                                        </div>
                                    </div>

                                    ) : (
                                    <div className="border border-black p-2 md:p-4 xl:p-6 mt-4 lg:mt-10 xxl:mt-20 md:flex items-center justify-between">
                                    <div className="md:w-[37%] larger:pr-20 mt-4">
                                    <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                        {grid.sub_heading}
                                    </h4>
                                    <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen mt-1">
                                        <span dangerouslySetInnerHTML={{ __html: grid.content }} />
                                    </p>
                                    </div>
        
                                    <div className="md:w-3/5">
                                    <img
                                        src={grid.image}
                                        alt="Command-Centre"
                                        className="w-full h-full object-contain"
                                    />
                                    </div>
                                    </div>
                                    )}
                                </div>
                            ))}

                          
                        </section>
                        ) : (<></>)
                    }

                    {/* Tenant Portal */}
                    <section className="py-8 md:py-14 xxl:pt-6 xxl:pb-24 larger:pt-20 larger:pb-44 vid bg-white">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                            <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-lg lg:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                                {pageData.tenant_portal.heading}
                            </h2>

                            <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-14 mt-4 md:mt-20 lg:mt-20 larger:mt-24">
                                <div className="lg:pr-14 xl:pr-20">
                                    {pageData.tenant_portal.icon_grid.length > 0 ? (
                                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-20 gap-y-14">
                                            {pageData.tenant_portal.icon_grid.map((icon, index) => (
                                                <div className="flex items-center flex-col text-center" key={index}>
                                                    <img
                                                        src={icon.image}
                                                        alt="Tenant-portal-1"
                                                        className="w-full object-contain max-w-[130px]"
                                                    />
                                                    <p className="font-AvenirLight text-xs md:text-[16pt] text-lightGray leading-normal mt-4">
                                                        {icon.title}
                                                    </p>
                                                </div>
                                            ))}
                                        </div>
                                    ) : (<></>)
                                    } 
                                </div>

                                <div className="border border-black p-4 md:p-8 lg:p-14 larger:p-20 h-full flex flex-col justify-center">
                                    <h4 className="font-SangBleuKingdomTrial text-xs xl:text-[17pt] larger:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                        {pageData.tenant_portal.box_text.heading}
                                    </h4>
                                    <p className="font-AvenirLight font-light text-xs larger:text-sm leading-normal text-fadeGreen mt-4">
                                        <span dangerouslySetInnerHTML={{ __html: pageData.tenant_portal.box_text.text }} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </section>

                </>
            ) : (<></>)}

            <Footer />
        </div>
    )
}

export default TechForward
