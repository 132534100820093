import React, { useEffect, useState } from 'react';

function Experiance() {
  // For Experiance change image to circle
  window.addEventListener('scroll', function() { 
    const connSlider= document.querySelector('.connSlider');
    const expbg = document.querySelector('.expbg');
    const expContent = document.querySelector('.expContent');
    const heroimgbg = document.querySelector('.heroimgbg');
    const text1 = document.querySelector('.content');
    const text2 = document.querySelector('.exp-text-two');
    const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight;
    // if(connSlider && expbg && expContent && heroimgbg && text1 && text2){
      if (bottom) {
        if(heroimgbg){
          heroimgbg.style.display = 'none';
        }
        if(text1){
          text1.style.display = 'none';
        }
        if(text2){
          text2.style.display = 'none';
        }
        if(expContent){
          expContent.style.display = 'none';
        }
        if(connSlider){
          connSlider.style.opacity = 1;
          connSlider.classList.remove('hidden');
        }
        if(expbg){
          expbg.style.transition = '1s';
          expbg.style.clipPath = `circle(${0}% at center 59.2%)`;
        }
      }
      else{
        if(heroimgbg){
          heroimgbg.style.display = 'block';
        }
        if(text1){
          text1.style.display = 'block';
        }
        if(text2){
          text2.style.display = 'block';
        }
        if(expContent){
          expContent.style.display = 'grid';
        }
        if(connSlider){
          connSlider.classList.add('hidden');
        }
        if(expbg){
          expbg.style.opacity = 1;
          expbg.style.transition = '1s';
          expbg.style.clipPath = `circle(${100}% at center center)`;
        }
      }
    // }
  })
  // End For Experiance change image to circle

  // Fetch Section Data
  const [SecTwoData, setSecTwoData] = useState(null);
  useEffect(() => {
    const fetchSecTwoData = async () => {
      try {
        const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/6');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSecTwoData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSecTwoData();
  }, []);
  // End of Fetch Section Data

  return ( 
    // Home page third section
    <section className='expSec relative'>
      {SecTwoData ? (
      <div className='expbg' style={{ backgroundImage: `url(${SecTwoData.acf.experience_ecoworld.background_image})` }}>
        <div className='min-h-screen pb-[20%] 2xl:pb-[55px] xl:pb-[10px] lg:pb-[0%] grid place-content-end expContent text-white text-center'>
            <h2 className='text-md md:text-lg md:text-6xl font-[500] font-SangBleuKingdomTrial'>
               {SecTwoData.acf.experience_ecoworld.heading}
            </h2>
            <p className='w-[95%] 2xl:w-[70%] xl:w-[85%] mx-auto mt-7 text-xs md:text-sm font-AvenirLight'>
              <span dangerouslySetInnerHTML={{ __html:  SecTwoData.acf.experience_ecoworld.content}} />
            </p>
        </div>
      </div>
      ) : ('')}
    </section>
    // End of Home page third section
  )
}

export default Experiance