import React, { useEffect, useState } from 'react';
import Banner from '../components/banner';
import Footer from '../components/footer';

const InspirationalSpaces = () => {

    // Fetch Page Data
   const [pageData, setpageData] = useState(null);
   useEffect(() => {
       const fetchpageData = async () => {
       try {
           const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/199');
           if (!response.ok) {
           throw new Error('Network response was not ok');
           }

           const data = await response.json();
           setpageData(data.acf);
       } catch (error) {
           console.error('Error fetching data:', error);
       }
       };

       fetchpageData();
   }, []);
    return (
        <div>
            {pageData ? (
                <>
                {/* Banner */}
                <Banner head={pageData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: pageData.banner.right_text }} />}
                    backgroundImage={pageData.banner.background_image} bgColor="bg-[#e6e7e8]" headColor="text-darkGreen" textColor="text-black 2xl:pr-[114px]" 
                    headWidth="w-[100%] larger:w-[23%] xxl:w-[26%] 2xl:w-[24%] xxxl:w-[21%] medium:w-[28%]"
                    secWidth="w-[90%] xxxl:w-[77%] larger:w-[78%] xxl:w-[85%] 2xl:w-[82%] medium:w-[90%]" 
                />

                {/* AIRE Cube */}
                <section className="py-10 md:py-28 bg-darkGreen seamSec vid">
                    <div className="container xxl:max-larger:max-w-[1220px]">
                    <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize">
                        {pageData.aire_cube.heading}
                    </h2>
                    <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white text-center xl:mt-5 xl:w-3/4 mx-auto">
                        <span dangerouslySetInnerHTML={{ __html: pageData.aire_cube.text }} />
                    </p>

                    <div className="relative overflow-hidden mt-10">
                        <img
                        src={pageData.aire_cube.bg_image}
                        alt="Aire---Ecoworld"
                        className="w-full max-md:h-[300px] max-h-[780px] object-bottom object-cover"
                        />
                        <div className="fdd"></div>
                    </div>
                    </div>
                </section>
                
                {/* Image & Text Section */}
                {pageData.image_text_grid.length > 0 ? (
                    <>
                        {pageData.image_text_grid.map((grid, index) => (
                            <section className="md:pb-20 relative bg-white vid" key={index}>
                                {index === 0 ? (
                                <>
                                <div className="container xxl:max-larger:max-w-[1220px] pb-10">
                                    <div className="border border-black p-2 md:p-4 xl:p-6 md:flex items-center justify-between bg-white">
                                        <div className="md:w-3/5">
                                        <img
                                            src={grid.image}
                                            alt="Command-Centre"
                                            className="w-full h-full object-contain"
                                        />
                                        </div>
                
                                        <div className="md:w-[37%] larger:pr-20 mt-4">
                                        <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                            {grid.heading}
                                        </h4>
                                        <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen md:mt-4">
                                            <span dangerouslySetInnerHTML={{ __html: grid.content }} />
                                        </p>
                                        </div>
                                    </div>
                                </div>
            
                                <div className="before:absolute before:top-0 before:left-0 before:right-0 before:h-[50%] before:bg-fadeGreen before:z-[-1]"></div>
                                </>
                                ) : (
                                    
                                <section className="pt-10 pb-10 vid bg-white">
                                    {index % 2 !== 0 ? (
                                        <div className="container xxl:max-larger:max-w-[1220px]">
                                            <div className="border border-black p-2 md:p-4 xl:p-6 flex flex-col-reverse md:flex-row items-center justify-between">
                                                <div className="md:w-[37%] larger:pr-20 mt-4">
                                                <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                                    {grid.heading}
                                                </h4>
                                                <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen md:mt-4">
                                                    <span dangerouslySetInnerHTML={{ __html: grid.content }} />
                                                </p>
                                                </div>

                                                <div className="md:w-3/5">
                                                <img
                                                    src={grid.image}
                                                    alt="Command-Centre"
                                                    className="w-full h-full object-contain"
                                                />
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="container xxl:max-larger:max-w-[1220px]">
                                            <div className="border border-black p-2 md:p-4 xl:p-6 md:flex items-center justify-between">
                                                <div className="md:w-3/5">
                                                <img
                                                    src={grid.image}
                                                    alt="Command-Centre"
                                                    className="w-full h-full object-contain"
                                                />
                                                </div>

                                                <div className="md:w-[37%] larger:pr-20 mt-4">
                                                <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize xl:w-2/3 larger:w-1/2">
                                                    {grid.heading}
                                                </h4>
                                                <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen md:mt-4">
                                                    <span dangerouslySetInnerHTML={{ __html: grid.content }} />
                                                </p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </section>

                                )}
                            </section>
                        ))}
                    </>
                ) : (<></>)}
                </>
            ) : (<></>)}

            {/* Footer */}
            <Footer />
        </div>
    )
}

export default InspirationalSpaces
