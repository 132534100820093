import React, { useEffect, useState } from 'react';
import Banner from "../components/banner";
import Footer from '../components/footer';
const Sustainability = () => {

    // Fetch Banner Data
    const [pageData, setpageData] = useState(null);
    useEffect(() => {
        const fetchpageData = async () => {
        try {
            const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/360');
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setpageData(data.acf);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchpageData();
    }, []);

    // Banner image filter
  const handleScroll = () => {
    // const wS = window.scrollY;
    // const seamSec = document.querySelector('.banner-bg');
    // const bannerH = seamSec.offsetHeight;
    // if(wS >= bannerH){
    //     seamSec.style.filter = 'brightness(170%) blur(1px)';
    // }
    // else{
    //     seamSec.style.filter = '';
    // }
  };
  useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
  });

    return(
        <div >
            {pageData ? (
                <>
                    <Banner className='min-h-screen ipad' head={pageData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: pageData.banner.right_text }} />}
                    backgroundImage={pageData.banner.background_image}  bgColor="bg-[#e6e7e8]" headColor="text-darkGreen" textColor="text-black" 
                    headWidth="w-[100%] larger:w-[23%] xxl:w-[26%] 2xl:w-[24%] xxxl:w-[21%] medium:w-[28%]"
                    secWidth="w-[90%] xxxl:w-[73%] larger:w-[75%] xxl:w-[81%] 2xl:w-[78%] medium:w-[86%]" 
                    />
                    {/* sponsers or partner at the top */}
                    <section className="py-8 md:py-10 xl:py-20 xxl:py-40 seamSec vid bg-white">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                            <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                                {pageData.partners_heading}
                            </h2>
                        </div>
                        {pageData.logos ? (
                            <div className="container xxl:max-larger:max-w-[1220px] mt-5">
                                {pageData.logos.length > 0 ? (
                                    <div className="flex flex-wrap max-md:gap-x-4 max-md:gap-y-8 md:gap-x-10 md:gap-y-20 items-center justify-center xl:w-3/4 mx-auto">
                                        {pageData.logos.map((image, index) => (
                                            <div className="w-2/5 md:w-1/5" key={index}>
                                            <img
                                                src={image.image}
                                                alt=""
                                                className="w-full md:max-w-[200px] object-contain"
                                            />
                                            </div>
                                        ))}
                                    </div>
                                ) : (<></>)}
                            </div>
                        ) : (<></>)}
                    </section>

                    {/* Asia's First Net Zero Waste Campus */}
                    <section className="py-8 md:py-10 xl:py-28 bg-darkGreen vid">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                        <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize">
                            {pageData.zero_waste_campus.heading}
                        </h2>
                        <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white text-center xl:mt-5 xl:w-3/4 mx-auto">
                            <span dangerouslySetInnerHTML={{ __html: pageData.zero_waste_campus.sub_heading }} />
                        </p>

                        <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 py-2 md:py-4 xl:py-6 bg-white mt-4 lg:mt-24">
                            <img
                            src={pageData.zero_waste_campus.image}
                            alt="sustain-bigger-item-1"
                            className="w-full object-contain"
                            />
                        </div>
                        </div>
                    </section>

                    {/* Sustainable Practices */}
                    <section className="py-8 md:py-10 xl:py-28 relative vid bg-white">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                        <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize">
                            {pageData.sustainable_practices.heading}
                        </h2>
                        <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white text-center xl:mt-5 xl:w-3/4 mx-auto">
                            <span dangerouslySetInnerHTML={{ __html: pageData.sustainable_practices.sub_heading }} />
                        </p>

                        <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 py-2 md:py-4 xl:py-6 bg-white mt-4 lg:mt-24">
                            <img
                            src={pageData.sustainable_practices.image}
                            alt="sustain-bigger-item-2"
                            className="w-full object-contain"
                            />
                        </div>
                        </div>

                        <div className="before:absolute before:top-0 before:left-0 before:right-0 before:h-[68%] before:bg-fadeGreen before:z-[-1]"></div>
                    </section>

                    {/* Air Quality */}
                    {pageData.image_text_grid ? (
                        <section className="pb-20 vid bg-white">
                            {pageData.image_text_grid.length > 0 ? (
                                <div className="container xxl:max-larger:max-w-[1220px]">
                                    {pageData.image_text_grid.map((grid, index) => (
                                        <div className='pt-20' key={index}>
                                        {index % 2 === 0 ? (

                                            <div className="border-2 border-lightGray p-2 md:p-4 xl:p-6 md:flex items-center justify-between" >
                                                <div className="md:w-3/5">
                                                    <img
                                                        src={grid.image}
                                                        alt="Command-Centre"
                                                        className="w-full h-full object-contain"
                                                    />
                                                </div>

                                                <div className="md:w-[37%] larger:pr-20 mt-4">
                                                    <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                                        {grid.heading}
                                                    </h4>
                                                    <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen md:mt-4">
                                                        <span dangerouslySetInnerHTML={{ __html: grid.text }} />
                                                    </p>
                                                </div>
                                            </div>

                                        ) : (
                                            <div className="border-2 border-lightGray p-2 md:p-4 xl:p-6 flex flex-col-reverse md:flex-row items-center justify-between">
                                                <div className="md:w-[37%] larger:pr-20 mt-4">
                                                <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                                    {grid.heading}
                                                </h4>
                                                <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen md:mt-4">
                                                    <span dangerouslySetInnerHTML={{ __html: grid.text }} />
                                                </p>
                                                </div>

                                                <div className="md:w-3/5">
                                                <img
                                                    src={grid.image}
                                                    alt="Command-Centre"
                                                    className="w-full h-full object-contain"
                                                />
                                                </div>
                                            </div>
                                        )}
                                        </div>
                                    ))}
                                </div>
                            ) : (<></>)}
                        </section>
                    ) : (<></>)}

                    {/* AIRE Case study */}
                    <section className="py-4 md:py-10 xl:py-14 xxl:py-20 larger:py-24 vid bg-white" id="CaseStudies">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                        <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                            {pageData.aire_case_study.heading}
                        </h2>

                        <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 py-2 md:py-4 xl:py-6 bg-white mt-4 lg:mt-20">
                            <img
                            src={pageData.aire_case_study.image}
                            alt="sustain-bigger-item-3"
                            className="w-full object-contain"
                            />
                        </div>

                        <div className="text-center mt-4 lg:mt-10">
                            <a
                            href={pageData.aire_case_study.link.url}
                            className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen inline-block group"
                            >
                            {pageData.aire_case_study.link.title}
                            <span className="ml-4 group-hover: animate-wiggle inline-block">
                                <img
                                src="/images/darkest-right-arrow.png"
                                alt="darkest-right-arrow"
                                className="w-full max-w-[14px] md:max-w-[20px] object-contain"
                                />
                            </span>
                            </a>
                        </div>
                        </div>
                    </section>

                    
                </>
            )  : (<></>)}
            <Footer />
        </div>
    )
}

export default Sustainability
