import Header from './header';
import React, { useEffect, useState } from 'react';

export default function Hero() {
  // Function to show image after some time and set cookie
  const hasRunCookie = document.cookie.split(';').some((item) => item.trim().startsWith('hasRun='));
  if (!hasRunCookie) {
    function showImageAndSetCookie() {
      document.getElementById('text').style.display = 'none';
      document.querySelector('.heroimgbg').style.opacity = '1';
      document.querySelector('.home-scroll').style.opacity = '1';
      document.cookie = 'hasRun=true';
    }
    setTimeout(showImageAndSetCookie, 6150);
  }
  
   // Set Height & width of screen after resize it
   const [screenWidth, setScreenWidth] = useState(window.innerWidth);
   const [screenHeight, setScreenHeight] = useState(window.innerHeight);
   useEffect(() => {
     const handleResize = () => {
       setScreenWidth(window.innerWidth);
       setScreenHeight(window.innerHeight);
     };
 
     window.addEventListener('resize', handleResize);
 
     return () => {
       window.removeEventListener('resize', handleResize);
     };
   }, []);
   // End of Set Height & width of screen after resize it

  // Scroll Animations
  useEffect(() => {
    const handleScroll = () => {
      var imgbg = document.querySelector('.heroimgbg');
      const text1 = document.querySelector('.exp-text-one');
      const text2 = document.querySelector('.exp-text-two');
      
      // Circle Animation
      let circlePer;
      if(screenWidth<=1025){circlePer = 46;}
      else{circlePer = 51;}
  
      const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 120;
      const circleSize = Math.min(circlePer, scrollPercentage);
  
      if(circleSize !== circlePer){
        text1.classList.remove('optAnimate');
        text2.classList.remove('optAnimateTwo');
        text2.classList.remove("opAnimateTwo");
      }
      else{
        text1.classList.add('optAnimate');
        text2.classList.add('optAnimateTwo');
        text2.classList.add("opAnimateTwo");
      }
      imgbg.style.clipPath = `circle(${71-circleSize}% at center)`;
  
      // Hide text
      var section = document.querySelector('.section');
      const textHidePos = scrollPercentage;
      if (textHidePos >= circleSize+15) {
        section.style.opacity = 0.1;
      } else {
        section.style.opacity = 1;
      }
      // End Circle Animation
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [screenWidth, screenHeight]);
  // End Scroll Animations

  return (
    // Home page hero section
    <div className="bg-darkGreen z-50 heroSection">
      {/* Header */}
      <header className="fixed inset-x-0 top-0 z-[999999] py-3">
        <Header/>
      </header>
      {/* End Header */}

      {/* Hero section */}
      <div>
        <div className="min-h-screen grid place-content-center contain">
          <div className="text-center heading zoomed-text" id='text'>
            <div className='amimation-main'>
              <div className='animation-sub'>
                <div className='animation-sub'>
                  <div className='animate-text'>
                    <div> <div>
                    {!document.cookie.includes('hasRun') && (
                      <>
                        <video
                          loop 
                          autoPlay
                          muted 
                          playsInline
                          style={{width:"100vw"}}
                          className={`scale-video desk-vid object-cover`}>
                          <source src="/images/scale.mp4" type="video/mp4"/>
                        </video>

                        <video
                          loop 
                          autoPlay
                          muted playsInline
                          style={{width:"100vw"}}
                          className={`scale-video ipad-vid object-cover`}>
                          <source src="/images/pro-scale.mp4" type="video/mp4"/>
                        </video>
                      </>
                     )}
                    </div> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!document.cookie.includes('hasRun') && (
            <div className="mt-10 flex items-center justify-center gap-x-6 scroll-area home-scroll opacity-0">
              <span className="scroll-text fixed bottom-[4%] z-50 text-sm leading-6 text-[#436d3d] font-AvenirLight">
                Keep Scrolling 
              </span>
              <svg className='scroll-icon fixed bottom-[2%] z-50' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/></svg>
            </div>
           )}
           {document.cookie.includes('hasRun') && ( 
            <div className="mt-10 flex items-center justify-center gap-x-6 scroll-area home-scroll opacity-1">
              <span className="scroll-text fixed bottom-[4%] z-50 text-sm leading-6 text-[#436d3d] font-AvenirLight">
                Keep Scrolling 
              </span>
              <svg className='scroll-icon fixed bottom-[2%] z-50' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"/></svg>
            </div>
            )}
        </div>
          {!document.cookie.includes('hasRun') && ( 
            <div className="heroimgbg opacity-0"></div>
          )}
          {document.cookie.includes('hasRun') && ( 
            <div className="heroimgbg opacity-1"></div>
          )}
      </div>
      {/* End Hero section */}
    </div>
    // Home page hero section
  )
}
