import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Connectivity = () => {
  
  // Set Index of slider of different screens
  let screenWidth = window.innerWidth;
  let slideIndex;
  if(screenWidth <= 1024){
    slideIndex = 2;
  }
  else if(screenWidth <= 991){
    slideIndex = 1;
  }
  else if(screenWidth <= 767){
    slideIndex = 2;
  }
  else{
    slideIndex = 0;
  }
  // End of Set Index of slider of different screens

  // Slider Settings
  const settings = {
    dots: false,
    prevArrow: false,
    nextArrow: false,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    speed: 1200,
    autoplaySpeed: 4000,
    cssEase: "linear",
    initialSlide: slideIndex,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          slidesToShow: 4
        }
      },

      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          slidesToShow: 3
        }
      },

      {
        breakpoint: 992,
        settings: {
          centerMode: false,
          slidesToShow: 3
        }
      },
      
      {
        breakpoint: 600,
        settings: {
          centerMode: false,
          slidesToShow: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          centerMode: true,
          centerPadding: '70px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 376,
        settings: {
          centerMode: true,
          centerPadding: '60px',
          slidesToShow: 1
        }
      },
      {
        breakpoint: 322,
        settings: {
          centerMode: true,
          centerPadding: '45px',
          slidesToShow: 1
        }
      }
    ]
  };
  // End of Slider Settings

  // Hide Scroll Text
  let isScrolling;
  const hideDuration = 500;
  window.addEventListener('scroll', function() {
    var wS = window.scrollY;

    // Hide scroll text when reach at 4th section
    const slideTop = document.querySelector('.slideSection');
    const scrollText = document.querySelector('.scroll-text');
    const scrollIcon = document.querySelector('.scroll-icon');
    if(slideTop){
      var secTop = slideTop.offsetTop;
      if(wS >= secTop){
        if(scrollText){
          scrollText.classList.remove('fixed');
        }
        if(scrollIcon){
          scrollIcon.classList.remove('fixed');
        }
      }
      else{
        if(scrollText){
          scrollText.classList.add('fixed');
        }
        if(scrollIcon){
          scrollIcon.classList.add('fixed');
        }
      }
    }
    // End of Hide scroll text

    // Hide scroll text while scrolling
    if(scrollText && scrollIcon){
      scrollText.style.opacity = 0; 
      scrollIcon.style.opacity = 0; 
      clearTimeout(isScrolling);
      isScrolling = setTimeout(() => {
        scrollText.style.opacity = 1;
        scrollIcon.style.opacity = 1; 
      }, hideDuration);
    }
    // End of hide scroll text

  });
  // End of hide text

  // Fetch Slider Data
  const [sliderData, setSliderData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/6');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSliderData(data.acf.slider.slider_data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  // End of slider data

  return (
    // Home page forth Section
    <div className="slideSection text-center text-white z-50 min-h-screen relative mt-[-100vh]" id='connectivity'>
      <div className="absolute top-[50%] md:top-[50%] translate-y-[-40%] h-fit" style={{width: "100%"}}>
      {sliderData.length > 0 ? (
        <Slider className="connSlider xl:pt-6 lg:pt-[0.65rem] mt-[-5px] md:mt-[0]" {...settings}>
          {sliderData.map((slide, index) => (
            <div className={`item ${index === 2 ? '' : (index < 2 ? 'itemL' : 'itemR')}`} key={index}>
              <Link to={slide.link} onClick={() => {window.scroll(0, 0); }}>
                <img className='' src={slide.image} alt={slide.heading} />
                <h4 className="mt-4 text-xxs md:text-nm leading-10 font-SangBleuKingdomTrial">{slide.heading}</h4>
                <p className="px-2 2xl:px-6 font-AvenirLight"><span dangerouslySetInnerHTML={{ __html: slide.sub_heading }} /></p>
              </Link>
            </div>
          ))}
        </Slider>
      ) : ('')}
      </div>
    </div>
    // End of Home page forth Section
  );

}
export default Connectivity
