import React from "react";
import { Link } from 'react-router-dom';
// import { HashLink } from 'react-router-hash-link';

const Footer = () => {
  return (
    <>
      <section className="bg-fadeGreen py-14 lg:py-24 footer">
        <div className="container xxl:max-larger:max-w-[1220px]">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-10 md:gap-14 larger:items-center">
            <div className="md-col-span-2">
              <div className="flex items-center h-full">
                <Link to="/" onClick={() => { window.scroll(0, 0); }}>
                  <img
                    src="/images/Ecoworld-logo-white-01.svg"
                    alt="Ecoworld-logo-white-01"
                    className="w-full max-w-[220px] md:max-w-[340px] object-contain"
                  />
                </Link>
                
              </div>
            </div>

            <div className="">
              <ul>
                <li>
                  <Link to="/about-brookfield"
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200">
                    About
                  </Link>
                </li>
                <li className="mt-2 md:mt-10">
                  <Link to="/campus"
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200">
                    The Campus
                  </Link>
                </li>
                <li className="mt-2 md:mt-10">
                  <Link to="/connectivity" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200">
                    Location Advantage
                  </Link>
                </li>
                
                <li className="mt-2 md:mt-10">
                <Link to="/technology" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200"
                  >
                    Tech Forward
                  </Link>
                </li>
              </ul>
            </div>

            <div className="">
              <ul>
                <li>
                  <Link to="/beyond-work" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200">
                    Beyond Work
                  </Link>
                </li>
                <li className="mt-2 md:mt-10">
                  <Link to="/inspirational-spaces"
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200">
                    Insipirational Spaces
                  </Link>
                </li>
                <li className="mt-2 md:mt-10">
                <Link to="/sustainability-wellness" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200"
                  >
                    Sustainability & Wellness
                  </Link>
                </li>
              </ul>
            </div>

            <div className="">
              <ul className="flex flex-col items-start">
                <li>
                <Link to="/events" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200"
                  >
                    Events
                  </Link>
                </li>
                <li className="mt-2 md:mt-10">
                  <Link to="/media-centre"
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200"

                  >
                    Media Centre
                  </Link>
                </li>
                <li className="mt-2 md:mt-10">
                  <Link to="/get-in-touch"
                    className="font-AvenirLight font-normal text-xs md:text-[16pt] larger:text-sm leading-normal text-[#878a83] hover:text-white duration-200"

                  >
                    Get in touch
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="grid md:grid-cols-2 lg:grid-flow-col lg:auto-cols-max justify-between gap-4 pt-10 mt-10 xxl:mt-32 border-t">
            <div>
              <Link to="/about-brookfield#BrookfieldProperties"
                    className="font-AvenirLight font-normal text-[12pt] sm:text-xs md:text-sm leading-normal text-white">
                    @2024 Brookfield Properties
              </Link>
            </div>
            <div>
              <Link to="/terms-and-conditions" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-[12pt] sm:text-xs md:text-sm leading-normal text-white">
                    Terms & Conditions
              </Link>
            </div>
            <div>
              <Link to="/privacy-policy" onClick={() => { window.scroll(0, 0); }}
                    className="font-AvenirLight font-normal text-[12pt] sm:text-xs md:text-sm leading-normal text-white">
                    Privacy Policy
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
