import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Header from '../components/header';
import Footer from "../components/footer";

const Events = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  
  // sliderHeaderHere
  const sliderHeaderHereSecond = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 1025,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  // Fetch Events
  const [events, setEvents] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/event-posts?_embed&per_page=100');
        const data = await response.json();
        setEvents(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Filter Count events
  const pastEvents = events.filter(event =>
    event._embedded &&
    event._embedded['wp:term'] &&
    event._embedded['wp:term'].some(term => term[0].name === 'Past')
  );
  const upcomingEvents = events.filter(event =>
    event._embedded &&
    event._embedded['wp:term'] &&
    event._embedded['wp:term'].some(term => term[0].name === 'Upcoming')
  );
  // Get the count of events
  const countOfPastEvents = pastEvents.length;
  const countOfUpcomingEvents = upcomingEvents.length;

  window.addEventListener('scroll', function () {
    const header = document.querySelector('header');
    if(header){
      header.style.backgroundColor = "#324031";
    }
  })

  return (
    <div>

      {/* Header */}
      <header className="fixed inset-x-0 top-0 z-[9999] bg-darkGreen py-3">
        <Header />
      </header>

      {/* Display Events */}
      <Tabs
        selectedIndex={selectedTab}
        onSelect={(index) => setSelectedTab(index)}
      >
        <section className="pt-8 md:pt-14 xl:pt-28 bg-fadeGreen">
          <div className='container xxl:max-larger:max-w-[1220px] pt-[4rem] pb-[3rem] md:pb-[0rem] md:pt-20'>
            <a
              a href="/"
              className="font-AvenirLight leading-normal text-white inline-flex items-center "
            >
              <svg
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
              </svg>
              <span className="ml-4">Explore Ecoworld</span>
            </a>

            <h1 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize mt-10 md:mt-20 xl:mt-28">
              Events
            </h1>

            <TabList className="flex justify-center mt-4 md:mt-10 xl:mt-20">
              {countOfPastEvents > 0 && (
                <Tab className="font-SangBleuKingdomTrial text-[14pt sm] md:text-sm font-medium text-gray-400 leading-normal capitalize cursor-pointer px-4 md:px-10 xl:px-20 py-2 md:py-4 xl:py-6 outline-none focus-visible:border border rounded-none">
                  Past Events
                </Tab>
              )}

              {countOfUpcomingEvents > 0 && (
                <Tab className="font-SangBleuKingdomTrial text-[14pt sm] md:text-sm font-medium text-gray-400 leading-normal capitalize cursor-pointer px-4 md:px-10 xl:px-20 py-2 md:py-4 xl:py-6 outline-none focus-visible:border border rounded-none">
                  Upcoming Events
                </Tab>
              )}
            </TabList>
          </div>
        </section>

        <section className="py-4 md:py-14 xl:pt-28 xl:pb-44">
          <div className="container xxl:max-larger:max-w-[1220px]">

            <TabPanel>
              {/* <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize mt-4 mb-20">
                Past Events
              </h2> */}

              <div className="Accessibility-slider mx-[-12px] pb-24 past-events">
                  <Slider {...sliderHeaderHereSecond}>
                    {events.filter(event => 
                      event._embedded && 
                      event._embedded['wp:term'] &&
                      event._embedded['wp:term'].some(term => term[0].name === 'Past')
                    )
                    
                    .map(event => (
                      <div className="px-3 h-full" key={event.id}>
                          <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 pt-2 md:pt-4 xl:pt-6 bg-white">
                            <div className="relative">
                              {event._embedded && event._embedded['wp:featuredmedia'] && (
                                <img className="w-full h-[400px] object-cover"
                                  src={event._embedded['wp:featuredmedia'][0].source_url}
                                  alt={event._embedded['wp:featuredmedia'][0].alt_text}
                                />
                              )}

                              <div className="px-4 py-4 md:py-8 lg:py-12">
                                <h2 className="font-SangBleuKingdomTrial text-xs xl:text-[15pt] larger:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                  <Link  onClick={() => { window.scroll(0, 0); }} to={`/single-event/${event.id}`} dangerouslySetInnerHTML={{ __html: event.title.rendered }} />
                                </h2>
                                <p className="font-AvenirLight font-light text-xs leading-normal text-gray-700 xl:mt-2">
                                  {new Date(event.date).toLocaleDateString()}
                                </p>
                              </div>

                            </div>
                        </div>
                        
                      </div>
                    ))}
                  </Slider>
              </div>
            </TabPanel>
            
            <TabPanel>
              {/* <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize mt-4 mb-20">
                Upcoming Events
              </h2> */}
              <div className="Accessibility-slider mx-[-12px] pb-24 upcoming-events">
                <Slider {...sliderHeaderHereSecond}>
                    {events.filter(event => 
                      event._embedded && 
                      event._embedded['wp:term'] &&
                      event._embedded['wp:term'].some(term => term[0].name === 'Upcoming')
                    )
                    .map(event => (
                      <div className="px-3 h-full" key={event.id}>
                          <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 pt-2 md:pt-4 xl:pt-6 bg-white">
                            <div className="relative">
                              {event._embedded && event._embedded['wp:featuredmedia'] && (
                                <img className="w-full h-[400px] object-cover"
                                  src={event._embedded['wp:featuredmedia'][0].source_url}
                                  alt={event._embedded['wp:featuredmedia'][0].alt_text}
                                />
                              )}

                              <div className="px-4 py-4 md:py-8 lg:py-12">
                                <h2 className="font-SangBleuKingdomTrial text-xs xl:text-[15pt] larger:text-sm font-medium text-fadeGreen leading-normal capitalize">
                                <Link  onClick={() => { window.scroll(0, 0); }} to={`/single-event/${event.id}`} dangerouslySetInnerHTML={{ __html: event.title.rendered }} />
                                </h2>
                                <p className="font-AvenirLight font-light text-xs leading-normal text-gray-700 xl:mt-2">
                                  {new Date(event.date).toLocaleDateString()}
                                </p>
                              </div>

                            </div>
                        </div>
                        
                      </div>
                    ))}
                  </Slider>
              </div>
            </TabPanel>
           
          </div>
        </section>
      </Tabs>

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default Events;
