import React, { useState, useEffect } from 'react';
import Banner from '../components/banner'
import Footer from '../components/footer';

const AboutUs = () => {


     // Fetch Section Data
    const [PageData, setPageData] = useState(null);
    useEffect(() => {
        const fetchPageData = async () => {
        try {
            const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/47');
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setPageData(data.acf);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchPageData();
    }, []);

    // Counter Increments
    const [count0, setCount0] = useState(0);
    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);
    const [sectionIsReached, setSectionIsReached] = useState(false);
    const [bookfieldReached, setbookfieldReached] = useState(false);
    useEffect(() => {
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        const sectionOffset = 350;
        const sectionElement = document.querySelector('.counterDiv');
        const bookfieldSection = document.querySelector('.bookFieldsCounter');
  
        // Box Counter
        if (sectionElement) {
          const sectionTop = sectionElement.offsetTop - sectionOffset;
          const sectionBottom = sectionTop + sectionElement.offsetHeight;
          setSectionIsReached(scrollPosition >= sectionTop && scrollPosition <= sectionBottom);
        }

        // Bookfield Properties Counter 
        if (bookfieldSection) {
            const bookfieldTop = bookfieldSection.offsetTop - sectionOffset;
            const bookfieldBottom = bookfieldTop + bookfieldSection.offsetHeight;
            setbookfieldReached(scrollPosition >= bookfieldTop && scrollPosition <= bookfieldBottom);
        }
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  
    useEffect(() => {
    // Bookfield Properties Counter
    if (bookfieldReached) {
        const interval1 = setInterval(() => {
          setCount0((prevCount) => {
            const newCount = prevCount + 1;
            if (newCount === 38) {
              clearInterval(interval1);
            }
            return newCount;
          });
        }, 2);
      
        const interval2 = setInterval(() => {
          setCount1((prevCount1) => {
            const newCount1 = prevCount1 + 1;
            if (newCount1 === 8) {
              clearInterval(interval2);
            }
            return newCount1;
          });
        }, 2);
      
        const interval3 = setInterval(() => {
          setCount2((prevCount2) => {
            const newCount2 = prevCount2 + 1;
            if (newCount2 === 12) {
              clearInterval(interval3);
            }
            return newCount2;
          });
        }, 2);
      
        return () => {
          clearInterval(interval1);
          clearInterval(interval2);
          clearInterval(interval3);
        };
    } else {
      setCount0(0);
      setCount1(0);
      setCount2(0);
    }

    }, [sectionIsReached, bookfieldReached]);
    
    return (
        <section className='about-main'>
          {PageData ? (
              <div>
                {/* Banner */}
                <Banner
                    head={PageData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: PageData.banner.right_text }} />}
                    backgroundImage={PageData.banner.background_image}
                    bgColor="bg-[#e6e7e8]"
                    headColor="text-darkGreen"
                    textColor="text-black"
                    headWidth="w-[100%] larger:w-[21%] xxl:w-[22%] 2xl:w-[21%] xxxl:w-[18.5%] medium:w-[23.97%]"
                    secWidth="w-[90%] xxxl:w-[72%] larger:w-[73.5%] xxl:w-[79%] xl:w-[83%] medium:w-[88%]"
                />

                {/* Brookfield Properties */}
                <section className="py-4 md:py-14 xxl:py-20 larger:py-24 seamSec vid bg-white" id="BrookfieldProperties">
                    <div className="container xxl:max-larger:max-w-[1220px]">
                        <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                            {PageData.brookfield_properties.heading}
                        </h2>
                        <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen text-center xl:mt-5 larger:max-w-[75%] mx-auto">
                            <span dangerouslySetInnerHTML={{ __html:  PageData.brookfield_properties.sub_heading}} />
                        </p>

                        <div className="border-2 border-lightGray p-1 sm:p-2 md:p-4 xl:p-6 bg-white mt-4 md:mt-14 xxl:mt-20">
                            <img
                            src={PageData.brookfield_properties.image}
                            alt="brookfield-properties-1"
                            className="w-full object-contain"
                            />
                        </div>
                    </div>
                </section>

                {/* location and counting of users */}
                <section className="max-md:pt-0 py-8 vid bg-white">
                    <div className="container xxl:max-larger:max-w-[1220px] bookFieldsCounter">
                        <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-10 larger:gap-14 xl:px-14 ">
                            <div className="lg:pr-8 larger:pr-14">
                            
                                {PageData.brookfield_properties.counter.length > 0 ? (
                                    <div className="grid grid-cols-2 md:max-lg:text-center md:grid-cols-3 justify-center gap-x-20 md:gap-y-14 ">
                                        {PageData.brookfield_properties.counter.map((counter, index) => (
                                            <div key={index}>
                                                <h4 className="font-Avenir font-normal text-[27pt] md:text-[50pt] larger:text-[55pt] leading-normal text-lightGray mt-4">
                                                    {/* {counter.heading} */}
                                                    {
                                                        index === 0 ? <span>{count0}+</span> :
                                                        index === 1 ? <span>${count1}B</span> :
                                                        index === 2 ? <span>{count2}+</span> :
                                                        null // or any default value
                                                    }

                                                </h4>
            
                                                <p className="font-SangBleuKingdomTrial text-[10pt] lg:max-xl:text-[9pt] larger:text-xs font-medium text-fadeGreen leading-normal capitalize">
                                                {counter.sub_heading}
                                                </p>
                                            </div>
                                            
                                        ))}
                                    </div>
                                ) : ( <p>Loading...</p> )}
                            </div>
                            {PageData.brookfield_properties.cities.length > 0 ? (
                            <div className="border-2 border-lightGray p-2 md:p-8 lg:p-14 larger:p-20 h-full grid grid-cols-2 grid-rows-4 items-center justify-center">
                                {PageData.brookfield_properties.cities.map((city, index) => (
                                    <div className="flex items-baseline gap-2 sm:gap-4 duration-200 p-2 larger:p-4" key={index}>
                                        <div className="border border-black p-[2px] md:p-1 rounded-full">
                                            <span className="bg-black w-2 md:w-3 h-2 md:h-3 block rounded-full"></span>
                                        </div>

                                        <h5 className="font-AvenirLight text-xs md:text-[16pt] text-lightGray leading-normal relative top-[-4px]">
                                            {city.name}
                                        </h5>
                                    </div>
                                ))}
                            </div>
                            ) : ( <p>Loading...</p> )}
                        </div>
                    </div>
                </section>

              </div>
          ) : ('')}
          
          {/* Footer */}
          <Footer />
        </section>
    )
}

export default AboutUs
