import React from 'react'

function ContactForm() {
  return (
    <div>
      <iframe 
            title="Contact Form" 
            src="https://ecoworld.geek-tasks.com/backend/contact-form/"
            width="100%" 
            height="550px" 
        />
    </div>
  )
}

export default ContactForm
