import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../components/banner";
import Footer from '../components/footer';
const Testimonial = () => {
    
    // Testimonial Slider
    const testimonial = {
        dots: false,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,

        responsive: [
        // {
        //   breakpoint: 1200,
        //   settings: {
        //     centerMode: false,
        //     slidesToShow: 3,
        //   },
        // },

        // {
        //   breakpoint: 1025,
        //   settings: {
        //     centerMode: false,
        //     slidesToShow: 2,
        //   },
        // },
        {
            breakpoint: 576,
            settings: {
            centerMode: false,
            slidesToShow: 1,
            },
        },
        ],
    };

    // at the bottom testimonials video
    const [feedPlaying, setfeedPlaying] = useState(false);
    const handleClickfeed = (videoElement) => {
        const overlayOn = document.querySelector(".overlay-on-feed");
        if (videoElement.paused || videoElement.ended) {
        videoElement.play();
        setfeedPlaying(true);
        overlayOn.classList.remove("bg-[#000000b2]");
        } else {
        videoElement.pause();
        setfeedPlaying(false);
        overlayOn.classList.add("bg-[#000000b2]");
        }
    };

    // Fetch Page Data
    const [pageData, setpageData] = useState(null);
    useEffect(() => {
        const fetchpageData = async () => {
        try {
            const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/493');
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setpageData(data.acf);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchpageData();
    }, []);

    return(
        <div>
            {pageData ? (
                <>
                    <Banner head={pageData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: pageData.banner.right_text }} />}
                    backgroundImage={pageData.banner.background_image} bgColor="bg-[#e6e7e8]" 
                    headColor="text-darkGreen" textColor="text-black" 
                    headWidth="w-[100%] larger:w-[16.5%] xxl:w-[17%] 2xl:w-[17%] xxxl:w-[14%] medium:w-[19.97%]"
                    secWidth="w-[90%] xxxl:w-[62%] larger:w-[63.5%] xxl:w-[68%] xl:w-[73%] medium:w-[78%]"
                    />
                    <div className='seamSec'>
                        {/* Testimonial */}
                        <section className="py-8 md:py-[9%] xxl:pt-28 larger:pb-32 relative vid bg-white">
                            <div className="container xxl:max-larger:max-w-[1220px]">
                                {/* testimonial slider */}
                                {pageData.slider.slide ? (
                                    <div className="mx-[-12px] pb-[12%]">
                                        {pageData.slider.slide.length > 0 ? (
                                            <Slider {...testimonial}>
                                                {pageData.slider.slide.map((slide, index) => (
                                                    <div className="px-3 h-full" key={index}>
                                                        <div className="border-2 border-lightGray p-2 md:p-4">
                                                            <div className="containedfeed relative">
                                                                {slide.video_url ? (
                                                                    // If video_url is present, show video
                                                                    <div className="relative">
                                                                        <video loop poster={slide.video_thumbnail} className={`video-played-feed-${index} xl:h-[460px] w-full h-[350px] object-cover border border-lightGray`}>
                                                                            <source src={slide.video_url} />
                                                                        </video>
                                                                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center overlay-on-feed">
                                                                            <img
                                                                                src={
                                                                                    feedPlaying
                                                                                        ? "/images/light-pause-icon.webp"
                                                                                        : "/images/light-play-icon.webp"
                                                                                }
                                                                                alt="light-play-icon"
                                                                                className="circle-play w-full max-w-[40px] md:max-w-[80px] cursor-pointer object-contain duration-300"
                                                                                onClick={() =>
                                                                                    handleClickfeed(
                                                                                        document.querySelector(
                                                                                            `.video-played-feed-${index}`
                                                                                        )
                                                                                    )
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    // If video_url is not present, show the image
                                                                    <img className="w-full xl:h-[460px] h-[350px] object-cover object-top" src={slide.video_thumbnail} alt="" />
                                                                )}
                                                            </div>
                                                            
                                                            <div className="px-4 py-4 md:py-8 lg:py-12">
                                                                <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen xl:mt-2">
                                                                    <span dangerouslySetInnerHTML={{ __html: slide.text }} />
                                                                </p>
                                                                <div className='flex justify-between mt-6 my-auto'>
                                                                    <div>
                                                                        <h2 className="font-AvenirLight text-xs md:text-sm font-bold text-fadeGreen leading-normal capitalize">
                                                                        - {slide.user}
                                                                        </h2>
                                                                        <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen">
                                                                            {slide.user_position}
                                                                        </p>
                                                                    </div>
                                                                    <div className="grid place-content-center">
                                                                        <img
                                                                            src="/images/revert-inverted-comma.png"
                                                                            alt="inverted commaa"
                                                                            className="w-full max-w-[40px] md:max-w-[60px] object-contain"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </Slider>
                                        ) : (<></>)}
                                    </div>
                                ) : (<></>)}
                            </div>
                        </section>
                    </div>
                </>
            ) : (<></>)}
            
            <Footer />
        </div>
    )
}
export default Testimonial
