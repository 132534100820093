import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer'
import ContactForm from '../components/contactForm';

function GetInTouch() {

   // Fetch Page Data
   const [ContactData, setContactData] = useState(null);
   useEffect(() => {
     const fetchContactData = async () => {
       try {
         const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/94');
         if (!response.ok) {
           throw new Error('Network response was not ok');
         }
 
         const data = await response.json();
         setContactData(data.acf);
       } catch (error) {
         console.error('Error fetching data:', error);
       }
     };
 
     fetchContactData();
   }, []);

   window.addEventListener('scroll', function () {
    const header = document.querySelector('header');
    if(header){
      header.style.backgroundColor = "#324031";
    }
  })

  return (
    <div>

      {/* Header */}
      <header className="fixed inset-x-0 top-0 z-50 bg-darkGreen py-3">
        <Header />
      </header>

      {ContactData ? (
      <>
        {/* get all your queries answered. */}
        <section className="relative pt-10">
          <div className="container xxl:max-larger:max-w-[1220px] py-20">
            <a
              href="/"
              className="font-AvenirLight leading-normal text-white inline-flex items-center ">
              <svg
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 448 512"
              >
                <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
              </svg>
              <span className="ml-4">Explore Ecoworld</span>
            </a>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 md:mt-14 xl:mt-24"> </div>
          </div>

          <div className="before:absolute before:top-0 before:left-0 before:right-0 before:h-[75%] md:before:h-[58%] before:bg-fadeGreen before:z-[-1]"></div>
        </section>

        {/* contact us */}
        <section className="py-8 md:py-14 xl:pb-20">
          <div className="container xxl:max-larger:max-w-[1220px]">
            <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
              {ContactData.get_in_touch.heading}
            </h2>

            {/* input fields */}
            <div className="border border-black p-2 md:p-4 xl:p-6 grid grid-cols-1 md:grid-cols-2 gap-8 lg:gap-12 items-center bg-white mt-4 lg:mt-14 xl:mt-20">
              <div>
                <img
                  src={ContactData.get_in_touch.image}
                  alt="Command-Centre"
                  className="w-full h-full object-contain"
                />
              </div>

              <div className="xl:pr-20">
                <ContactForm />
              </div>
            </div>
          </div>
        </section>

        {/* Download Brochure */}
        <section className="py-8 md:py-14 xl:py-44 bg-[#e6e7e8]">
          <div className="container xxl:max-larger:max-w-[1220px]">
            <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
              To know more about Ecoworld
            </h2>

            {/* input fields */}
            <form action="">
              <div className="flex flex-col md:flex-row gap-4 md:gap-12 justify-center items-center mt-10 xl:mt-20">
                <div className="text-end max-md:w-full">
                  <input
                    required
                    type="email"
                    placeholder="Email*"
                    className="border-0 focus:border-black border-b-2 border-black focus-visible:outline-0 w-full md:w-[400px] bg-transparent font-SangBleuKingdomTrial text-[12pt] md:text-[16pt] font-medium text-fadeGreen placeholder:text-[#bcbfbe] leading-normal capitalize"
                  />
                </div>

                <div className="max-md:mt-4">
                  <a href="https://kinnect365-my.sharepoint.com/:b:/g/personal/bizan_fcbsix_in/Ed0Mj0sewZtMlBmEaL6fp38BrtQAB9l1dR5Rz-WzdvmVRQ?e=apdcAz "
                    type="submit"
                    className="font-SangBleuKingdomTrial text-[12pt] lg:text-sm font-medium text-white placeholder:text-[#bcbfbe] leading-normal capitalize bg-fadeGreen 
                    px-14 rounded-full py-3 whitespace-nowrap"
                  >
                    Download Brochure
                  </a>
                </div>
              </div>
            </form>
          </div>
        </section>
      </>
      ): (<></>)}

      {/* Footer */}
      <Footer />
    </div>
  )
}

export default GetInTouch
