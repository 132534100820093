import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';

const TermsCondition = () => {
  // Fetch Page Data
  const [pageData, setpageData] = useState(null);
  useEffect(() => {
      const fetchpageData = async () => {
      try {
          const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/233');
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setpageData(data.acf.content);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      };

      fetchpageData();
  }, []);

  window.addEventListener('scroll', function () {
    const header = document.querySelector('header');
    if(header){
      header.style.backgroundColor = "#324031";
    }
  })

  return (
    <div>

      <header className="fixed inset-x-0 top-0 z-50 bg-darkGreen py-3">
          <Header />
      </header>

       {/* explore more topics */}
       <section className="py-8 md:py-14 xxl:py-20">
        <div className='container xxl:max-larger:max-w-[1220px] pt-20'>
          <a
            href="/"
            className="font-AvenirLight leading-normal text-fadeGreen inline-flex items-center "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="1em"
              viewBox="0 0 448 512"
            >
              <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
            </svg>
            <span className="ml-4">Explore Ecoworld</span>
          </a>
        </div>
      </section>

      {/* Terms and Conditions */}
      {pageData ? (
        <section className="py-4 pb-10 md:pb-28 xxl:pb-40">
          {pageData.length > 0 ? (
          <div className="container xxl:max-larger:max-w-[1220px]">
            {pageData.map((data, index) => (
              <div key={index}>
                <h2 className={`${index > 0 ? ('mt-10 md:mt-[9%]'): ('')} font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize`}>
                  {data.heading}
                </h2>
                <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen mt-2 xl:mt-8 policy-text">
                  <span dangerouslySetInnerHTML={{ __html: data.text }} />
                </p>
              </div>
            ))}
          </div>
          ) : (<></>)}
        </section>
      ) : (<></>)}
      
      <Footer />
    </div>
  )
};

export default TermsCondition;
