import React, { useEffect, useState } from 'react';
import Banner from "../components/banner";
import SeamlessConnectivity from "../components/seamlessConnectivity.js";
import Footer from '../components/footer';

const ConnectivityPage = () => {
     // Fetch Banner Data
     const [bannerData, setbannerData] = useState(null);
     useEffect(() => {
         const fetchbannerData = async () => {
         try {
             const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/115');
             if (!response.ok) {
             throw new Error('Network response was not ok');
             }
 
             const data = await response.json();
             setbannerData(data.acf);
         } catch (error) {
             console.error('Error fetching data:', error);
         }
         };
 
         fetchbannerData();
     }, []);

    // Banner image filter
    // const handleScroll = () => {
    //     const wS = window.scrollY;
    //     const seamSec = document.querySelector('.banner-bg');
    //     if(seamSec){
    //         const bannerH = seamSec.offsetHeight;
    //         if(bannerH){
    //             if(wS >= bannerH){
    //                 seamSec.style.filter = 'brightness(145%)';
    //             }
    //             else{
    //                 seamSec.style.filter = '';
    //             }
    //         }
    //     }
    // };
    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll);
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    // });

    return(
        <div className='connectivity-page'>
            {bannerData ? (
                <Banner
                    head={bannerData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: bannerData.banner.right_text }} />}
                    backgroundImage={bannerData.banner.background_image}
                    bgColor="bg-[#e6e7e8]" headColor="text-darkGreen" textColor="text-black" 
                    headWidth="w-[100%] larger:w-[23%] xxl:w-[26%] 2xl:w-[24%] xxxl:w-[21%] medium:w-[28%]"
                    secWidth="w-[90%] xxxl:w-[77%] larger:w-[78%] xxl:w-[85%] 2xl:w-[82%] medium:w-[90%]"
                />
            )  : (<></>)}
            <SeamlessConnectivity />
            <Footer />
        </div>
    )
}
export default ConnectivityPage;