import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Footer from '../components/footer'

function ThankYou() {
    return (
        <>
            {/* Header */}
            <header className="fixed inset-x-0 top-0 z-50 bg-darkGreen py-3">
                <Header />
            </header>

            {/* get all your queries answered. */}
            <section className="relative pt-10">
            <div className="container xxl:max-larger:max-w-[1220px] py-20">
                <a
                href="/"
                className="font-AvenirLight leading-normal text-white inline-flex items-center ">
                <svg
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 448 512"
                >
                    <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
                </svg>
                <span className="ml-4">Explore Ecoworld</span>
                </a>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 md:mt-14 xl:mt-24"> </div>
            </div>

            <div className="before:absolute before:top-0 before:left-0 before:right-0 before:h-[75%] md:before:h-[58%] before:bg-fadeGreen before:z-[-1]"></div>
            </section>

            <section className="py-8 md:py-14 xl:pb-20">
                <div className="container xxl:max-larger:max-w-[1220px]">
                    <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xl font-medium text-fadeGreen leading-normal capitalize"> Thankyou for submitting  your query, our team will get in touch with you shortly.</h2>
                </div>
            </section>
            {/* Footer */}
            <Footer />
        </>
    )
}

export default ThankYou