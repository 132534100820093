/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
const SeamlessConnectivity = () => {
  // Fetch Page Data
  const [pageData, setpageData] = useState(null);
  useEffect(() => {
    const fetchpageData = async () => {
      try {
        const response = await fetch(
          "https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/115"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setpageData(data.acf);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchpageData();
  }, []);

  const [showStatus1, setShowStatus1] = useState(false);
  const [showStatus2, setShowStatus2] = useState(false);
  const [showStatus3, setShowStatus3] = useState(false);
  const [showStatus4, setShowStatus4] = useState(false);
  const [showStatus5, setShowStatus5] = useState(false);
  const [showStatus6, setShowStatus6] = useState(false);
  const [showStatus7, setShowStatus7] = useState(false);
  const [showStatus8, setShowStatus8] = useState(false);
  const [showStatus10, setShowStatus10] = useState(false);
  const [showStatus11, setShowStatus11] = useState(false);
  const [showStatus12, setShowStatus12] = useState(false);
  const [showStatus13, setShowStatus13] = useState(false);
  const [showStatus14, setShowStatus14] = useState(false);
  const [showStatus15, setShowStatus15] = useState(false);

  const handleRadioChange = (e) => {
    if (e.target.id === "status1") {
      setShowStatus1(true);
    } else {
      setShowStatus1(false);
    }
    if (e.target.id === "status2") {
      setShowStatus2(true);
    } else {
      setShowStatus2(false);
    }
    if (e.target.id === "status3") {
      setShowStatus3(true);
    } else {
      setShowStatus3(false);
    }
    if (e.target.id === "status4") {
      setShowStatus4(true);
    } else {
      setShowStatus4(false);
    }
    if (e.target.id === "status5") {
      setShowStatus5(true);
    } else {
      setShowStatus5(false);
    }
    if (e.target.id === "status6") {
      setShowStatus6(true);
    } else {
      setShowStatus6(false);
    }
    if (e.target.id === "status7") {
      setShowStatus7(true);
    } else {
      setShowStatus7(false);
    }
    if (e.target.id === "status8") {
      setShowStatus8(true);
    } else {
      setShowStatus8(false);
    }
    if (e.target.id === "status10") {
      setShowStatus10(true);
    } else {
      setShowStatus10(false);
    }
    if (e.target.id === "status11") {
      setShowStatus11(true);
    } else {
      setShowStatus11(false);
    }
    if (e.target.id === "status12") {
      setShowStatus12(true);
    } else {
      setShowStatus12(false);
    }
    if (e.target.id === "status13") {
      setShowStatus13(true);
    } else {
      setShowStatus13(false);
    }
    if (e.target.id === "status14") {
      setShowStatus14(true);
    } else {
      setShowStatus14(false);
    }
    if (e.target.id === "status15") {
      setShowStatus15(true);
    } else {
      setShowStatus15(false);
    }
  };

  return (
    <section className="py-8 md:py-14 xl:pt-28 xl:pb-32 seamSec bg-white">
      {pageData ? (
        <div className="container xxl:max-larger:max-w-[1220px] larger:px-[100px]">
          <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
            {pageData.seamless_connectivity.heading}
          </h2>
          <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-black text-center xl:mt-5">
            <span
              dangerouslySetInnerHTML={{
                __html: pageData.seamless_connectivity.sub_heading,
              }}
            />
          </p>

          {/* {/ bottom map and scroller places /} */}
          <div className="mt-4 md:mt-10 lg:mt-16 larger:mt-28 lg:flex items-center justify-between">
            <div className="lg:w-8/12">
              <div className="border-2 border-lightGray p-2 md:p-4">
                <div className="relative">
                  <img
                    src={pageData.seamless_connectivity.map_image}
                    alt="light-logo"
                    className="w-full object-cover h-[260px] s:h-[360px] lg:h-[525px] md:h-[560px]"
                  />
                  {/* Map Pointer  */}
                  {showStatus1 && ""}

                  {showStatus2 && (
                    <div className="w-[35%] absolute top-[3px] right-[43px] s:right-[35px] sm:right-[94px] md:right-[128px] lg:right-[102px] medium:right-[143px] xl:right-[148px] xxl:right-[151px] larger:right-[200px] xxxl:right-[220px] xxxxl:right-[247px]">
                      <div className="px-2 pointer-blink mx-auto"> </div>
                      <div className="bg-white p-2 md:p-4 mx-auto mt-5 pointer-loc">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status1"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          KR Puram
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                    </div>
                  )}

                  {showStatus3 && (
                    <div className="w-[35%] absolute top-[58px] s:top-[79px] sm:top-[76px] md:top-[120px] lg:top-[115px] left-[33px] s:left-[19.5px] sm:left-[79.3px] md:left-[107px] lg:left-[82px] medium:left-[121px] xl:left-[127px] xxl:left-[132px] larger:left-[176px] xxxl:left-[198px] xxxxl:left-[228px]">
                      <div className="px-2 pointer-blink mx-auto"> </div>
                      <div className="bg-white p-2 md:p-4 mx-auto mt-5 pointer-loc">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status2"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Biappanahali Metro Station
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                    </div>
                  )}

                  {showStatus4 && (
                    <div className="w-[35%] absolute top-[79px] s:top-[113px] sm:top-[104px] md:top-[170px] lg:top-[158px] left-[9px] s:left-[-13.5px] sm:left-[79.3px] md:left-[55px] lg:left-[38px] medium:left-[71px] xl:left-[78px] xxl:left-[84px] larger:left-[128px] xxxl:left-[148px] xxxxl:left-[175px]">
                      <div className="px-2 pointer-blink mx-auto"> </div>
                      <div className="bg-white p-2 md:p-4 mx-auto mt-5 pointer-loc">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status3"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Indiranagar
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                    </div>
                  )}

                  {showStatus5 && (
                    <div className="w-[35%] absolute top-[32px] medium:top-[120px] lg:top-[150px] md:top-[155px] sm:top-[70px] s:top-[118px] larger:top-[95px] xxxl:top-[80px] xxxxl:top-[62px] right-[83px] s:right-[88px] sm:right-[150px] md:right-[212px] lg:right-[181px] medium:right-[220px] xl:right-[227px] xxl:right-[232px] larger:right-[276px] xxxl:right-[297px] xxxxl:right-[328px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status4"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Sakra Hospital
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus6 && (
                    <div className="w-[35%] absolute top-[26px] medium:top-[120px] lg:top-[145px] md:top-[149px] sm:top-[36px] s:top-[93px] larger:top-[86px] xxxl:top-[80px] xxxxl:top-[57px] right-[65px] s:right-[67px] sm:right-[125px] md:right-[179px] lg:right-[154px] medium:right-[187px] xl:right-[194px] xxl:right-[200px] larger:right-[246px] xxxl:right-[265px] xxxxl:right-[295px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status5"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          New Horizon College
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus7 && (
                    <div className="w-[35%] absolute top-[-4px] xxl:top-[5px] medium:top-[8px] lg:top-[36px] md:top-[35px] sm:top-[-10px] s:top-[24px] larger:top-[-20px] xxxl:top-[-35px] xxxxl:top-[-53px] right-[49px] s:right-[65px] sm:right-[104px] md:right-[142px] lg:right-[116px] medium:right-[187px] xl:right-[160px] xxl:right-[165px] larger:right-[209px] xxxl:right-[230px] xxxxl:right-[260px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status7"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Marathahalli
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {/*  */}
                  {showStatus8 && (
                    <div className="w-[35%] absolute top-[-24px] xxl:top-[-8px] medium:top-[-2px] lg:top-[24px] md:top-[22px] sm:top-[-16px] s:top-[35px] xs:top-[22px] larger:top-[-30px] xxxl:top-[-46px] xxxxl:top-[-64px] left-[24px] s:left-[8px] xs:left-[30px] sm:left-[70px] md:left-[88px] lg:left-[66px] medium:left-[187px] xl:left-[110px] xxl:left-[114px] larger:left-[174px] xxxl:left-[180px] xxxxl:left-[210px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status8"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Leela Palace Hotel
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {/* nitnth is missing */}

                  {showStatus10 && (
                    <div className="w-[35%] absolute top-[44px] medium:top-[150px] lg:top-[176px] md:top-[185px] sm:top-[84px] s:top-[116px] xs:top-[122px] larger:top-[120px] xxl:top-[146px] xxxl:top-[108px] xxxxl:top-[88px] right-[83px] s:right-[88px] xs:right-[112px] sm:right-[150px] md:right-[212px] lg:right-[181px] medium:right-[220px] xl:right-[227px] xxl:right-[232px] larger:right-[276px] xxxl:right-[297px] xxxxl:right-[330px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status10"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Adarsh Pal Retreat
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus11 && (
                    <div className="w-[35%] absolute top-[30px] medium:top-[110px] lg:top-[136px] md:top-[144px] sm:top-[24px] s:top-[116px] xs:top-[102px] larger:top-[120px] xxl:top-[108px] xxxl:top-[108px] xxxxl:top-[80px] right-[88px] s:right-[88px] xs:right-[120px] sm:right-[156px] md:right-[228px] lg:right-[192px] medium:right-[236px] xl:right-[236px] xxl:right-[244px] larger:right-[276px] xxxl:right-[297px] xxxxl:right-[344px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status10"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Ecospace (Proposed metro station)
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus12 && (
                    <div className="w-[35%] absolute top-[64px] medium:top-[142px] lg:top-[170px] md:top-[176px] sm:top-[82px] s:top-[116px] xs:top-[118px] larger:top-[120px] xxl:top-[140px] xxxl:top-[108px] xxxxl:top-[80px] right-[98px] s:right-[88px] xs:right-[130px] sm:right-[170px] md:right-[246px] lg:right-[212px] medium:right-[256px] xl:right-[256px] xxl:right-[262px] larger:right-[276px] xxxl:right-[297px] xxxxl:right-[358px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status10"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          PVR
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus13 && (
                    <div className="w-[30%] sm:w-[35%] absolute top-[48px] medium:top-[100px] lg:top-[126px] md:top-[132px] sm:top-[52px] s:top-[116px] xs:top-[104px] larger:top-[120px] xxl:top-[96px] xxxl:top-[56px] xxxxl:top-[36px] left-[-16px] s:left-[88px] xs:left-[-18px] sm:left-[0px] md:left-[-14px] lg:left-[-34px] medium:left-[256px] xl:left-[12px] xxl:left-[16px] larger:left-[276px] xxxl:left-[86px] xxxxl:left-[114px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status10"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Koramangala
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus14 && (
                    <div className="w-[35%] absolute top-[88px] medium:top-[190px] lg:top-[216px] md:top-[222px] sm:top-[110px] s:top-[164px] xs:top-[150px] larger:top-[120px] xxl:top-[184px] xxxl:top-[146px] xxxxl:top-[126px] left-[16px] s:left-[-4px] xs:left-[18px] sm:left-[56px] md:left-[70px] lg:left-[50px] medium:left-[256px] xl:left-[92px] xxl:left-[94px] larger:left-[276px] xxxl:left-[162px] xxxxl:left-[194px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status10"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          HSR Layout
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}

                  {showStatus15 && (
                    <div className="w-[35%] absolute top-[48px] medium:top-[150px] lg:top-[176px] md:top-[185px] sm:top-[84px] s:top-[124px] xs:top-[126px] larger:top-[120px] xxl:top-[148px] xxxl:top-[108px] xxxxl:top-[112px] right-[83px] s:right-[88px] xs:right-[112px] sm:right-[150px] md:right-[212px] lg:right-[181px] medium:right-[220px] xl:right-[227px] xxl:right-[232px] larger:right-[276px] xxxl:right-[297px] xxxxl:right-[330px]">
                      <div className="bg-white p-2 md:p-4 mx-auto mb-5 pointer-loc-bottom">
                        <img
                          src="images/hero.jpg"
                          className="rounded-lg"
                          alt="status10"
                        />
                        <h4 className="font-Avenir font-extrabold text-[8pt] sm:text-xs text-lightGray pt-2 md:pt-4">
                          Ecoworld Buildings 5-8, The Bay
                        </h4>
                        <Link className="font-AvenirLight md:text-[10pt] text-[6pt]">
                          Get Direction{" "}
                        </Link>
                      </div>
                      <div className="px-2 pointer-blink mx-auto"> </div>
                    </div>
                  )}
                  {/*  */}
                  {/* Map Pointers */}
                </div>
              </div>
            </div>

            {pageData.seamless_connectivity.locations.length > 0 ? (
              <div className="lg:w-3/12 max-h-[500px] md:max-h-[570px] larger:max-h-[650px] overflow-y-scroll scroller-places max-lg:mt-10 md:pr-4">
                {pageData.seamless_connectivity.locations.map(
                  (location, index) => (
                    <label htmlFor={`status${index + 1}`}>
                      <div
                        className="flex items-start gap-2 sm:gap-4 larger:gap-6 hover:bg-gray-100/50 duration-200 p-2 cursor-pointer"
                        key={index}
                      >
                        {/* <div className="border border-black p-[2px] md:p-1 rounded-full mt-1 lg:mt-2">
                      <span className="bg-black w-2 md:w-3 h-2 md:h-3 block rounded-full"></span>
                    </div>

                    <div>
                    <h4 className="font-Avenir font-extrabold text-[12pt] sm:text-xs md:text-sm text-lightGray">
                    {location.name}
                    </h4>
                    <h5 className="font-AvenirLight text-[12pt] sm:text-xs md:text-sm text-lightGray mt-1 md:mt-2 larger:mt-5">
                    {location.distance}
                    </h5>
                  </div> */}
                        <input
                          className="mt-1 lg:mt-3"
                          id={`status${index + 1}`}
                          type="radio"
                          name="status"
                          onChange={handleRadioChange}
                        />
                        <div>
                          <h4 className="font-Avenir font-extrabold text-[12pt] sm:text-xs md:text-sm text-lightGray">
                            {location.name}
                          </h4>
                          <h5 className="font-AvenirLight text-[12pt] sm:text-xs md:text-sm text-lightGray mt-1 md:mt-2 larger:mt-5">
                            {location.distance}
                          </h5>
                        </div>
                      </div>
                    </label>
                  )
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </section>
  );
};
export default SeamlessConnectivity;
