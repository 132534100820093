import React from 'react';
import Header from './header';

const Banner = (props) => {
    // Scroll circle animation
    let screenWidth = window.innerWidth;
    window.addEventListener('scroll', function () {
        const bannerBg = document.querySelector('.banner-bg');
        const oppTextOne = document.querySelector('.oppTextOne');
        const oppSubHead = document.querySelector('.oppSubHead');
        const oppTextTwo = document.querySelector('.oppTextTwo');
        const extopText = document.querySelector('.extopText');
        const seamSec = document.querySelector('.seamSec');
        var scrollPos = window.scrollY;
        if(seamSec){
            var seamTop = seamSec.offsetHeight;
            var headerColor = seamSec.offsetTop;
        }

       // Circle Animation
        let circlePer;
        if(screenWidth<=1025){circlePer = 46;}
        else{circlePer = 51;}
        const scrollPercentage = (window.scrollY / (document.body.scrollHeight - window.innerHeight)) * 280;
        const circleSize = Math.min(circlePer, scrollPercentage);
        if(circleSize !== circlePer){
            if(extopText){
                extopText.style.opacity = '0';
            }
            if(oppTextOne){
                oppTextOne.classList.remove('optAnimate');
            }
            if(oppSubHead){
                oppSubHead.classList.remove('optAnimateOne');
            }
            if(oppTextTwo){
                oppTextTwo.classList.remove('optAnimateTwo');
                oppTextTwo.classList.remove("opAnimateTwo");
            }
        }
        else{
            if(extopText){
                extopText.style.opacity = '1';
            }
            if(oppTextOne){
                oppTextOne.classList.add('optAnimate');
            }
            if(oppSubHead){
                oppSubHead.classList.add('optAnimateOne');
            }
            if(oppTextTwo){
                oppTextTwo.classList.add('optAnimateTwo');
                oppTextTwo.classList.add("opAnimateTwo");
            }
        }
        if(bannerBg){
            bannerBg.style.clipPath = `circle(${71-circleSize}% at center)`;
        }
        // End Circle Animation

        // Hide scroll text
        const header = document.querySelector('header');
        const scrollText = document.querySelector('.scroll-text');
        const scrollIcon = document.querySelector('.scroll-icon');
        if(header){
            if(scrollPos >= headerColor-1500){
                header.style.backgroundColor = "#324031";
            }
            else{
                header.style.backgroundColor = "transparent";
            }
        }
        if(seamTop){
            if(scrollPos >= seamTop){
                if(scrollText){
                    scrollText.classList.remove('fixed');
                }
                if(scrollIcon){
                    scrollIcon.classList.remove('fixed');
                }
              }
            else{
                if(scrollText){
                    scrollText.classList.add('fixed');
                }
                if(scrollIcon){
                    scrollIcon.classList.add('fixed');
                }
            }
        }
        // End hide scroll text

        // Change color of scroll text
        if(scrollPos >= 500){
            if(scrollText){
                scrollText.style.color = '#000000';
            }
            if(scrollIcon){
                scrollIcon.style.fill = '#000000';
            }
          }
        else{
            if(scrollText){
                scrollText.style.color = '#ffffff';
            }
            if(scrollIcon){
                scrollIcon.style.fill = '#ffffff';
            }
        }
        // End Change color of scroll text
    })
    // End Scroll circle animation

    return (
        // Banner & Circle animation section of all pages except home
        <div className={`${props.bgColor}`}>
            {/* Header */}
            <header className="fixed inset-x-0 top-0 z-[9999] py-3">
                <Header />
            </header>
            {/* End Header */}

            {/* Banner Image */}
            <div className="min-h-screen grid place-content-center banner-bg" style={{ backgroundImage: `url(${props.backgroundImage})` }}></div>
            {/* End of Banner Image */}

            {/* Explore other topic Section */}
            <div className={`relative container xxl:max-larger:max-w-[1220px] mx-auto exploreSec`}>
                <div className='absolute top-[13%] xl:top-[16%]'>
                    <a href="/" className={`${props.textColor} fixed top-[13%] xl:top-[16%] opacity-0 transition-[opacity] extopText`}>
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" /></svg>
                        <span className='relative top-[-20px] left-[25px] font-AvenirLight'>Explore Ecoworld</span>
                    </a>
                </div>
            </div>
            {/* End Section */}

            {/* Circle Section */}
            <div className={`relative container mx-auto exploreSec ${props.secWidth}`}>
                <div className='flex justify-between'>
                    <div className='content'>
                        <h2 className={`${props.headColor} text-sm md:text-md xl:text-lg xxxxl:text-xl fixed top-[25%] 2xl:top-[35%] xl:top-[30%] md:top-[40%] opacity-0 transition-[opacity] oppTextOne font-SangBleuKingdomTrial ${props.headWidth}`}>{props.head}</h2>
                        <h4 className={`${props.headColor} text-xxxxs xs:text-xxxs md:text-xs xxl:text-nm fixed top-[25%] 2xl:top-[35%] xl:top-[30%] md:top-[40%] opacity-0 transition-[opacity] oppSubHead w-[100%] xxl:w-[20%] 2xl:w-[21%] xxxl:w-[18.5%] medium:w-[21%] font-SangBleuKingdomTrial`}>{props.leftText}</h4>
                    </div>
                    <div className='content'>
                        <p className={`${props.textColor} text-xxxxs xs:text-xxxs md:text-xs xxl:text-nm text-center md:text-left ms-auto opacity-0 oppTextTwo fixed top-[68%] xl:top-[50%] md:top-[55%] right-[5.5%] xxl:right-[6%] xl:right-[5%] md:right-[2%] w-[90%] 2xl:w-[26%] xl:w-[26%] lg:w-[32%] md:w-[42%] font-AvenirLight`}>
                            {props.rightText} <span className='block mt-5'>{props.rightTextOne}</span>
                        </p>
                    </div>
                </div>
                <div className="mt-10 flex items-center justify-center gap-x-6 scroll-area">
                    <span className="scroll-text fixed bottom-[10%] z-50 text-sm leading-6 text-white">
                        Keep Scrolling
                    </span>
                    <svg className='scroll-icon fixed bottom-[6%] z-50' xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z" /></svg>
                </div>
            </div>
            <div className='heightSection'></div>
            {/* End Circle Section */}

        </div>
        // End of Section
    );
};

export default Banner;