import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Banner from "../components/banner";
import Footer from '../components/footer';

function BeyondWork() {

    // Fetch Page Data
    const [pageData, setpageData] = useState(null);
    useEffect(() => {
        const fetchpageData = async () => {
        try {
            const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/444');
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }

            const data = await response.json();
            setpageData(data.acf);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        };

        fetchpageData();
    }, []);
    const [currentActiveSlide, setcurrentActiveSlide] = useState(0);
    const [currentBaySlide, setcurrentBaySlide] = useState(0);
    // slider
    const sliderPicture = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        autoplay: false,
        centerMode: true,
        centerPadding: "0px",
        afterChange: (index) => setcurrentActiveSlide(index),
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                centerMode: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerMode: false,
                slidesToShow: 1,
              },
            },
        ],
    };
    const baysliderPicture = {
        dots: false,
        arrows: true,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        autoplay: false,
        centerMode: true,
        centerPadding: "0px",
        afterChange: (index) => setcurrentBaySlide(index),
        responsive: [
            {
              breakpoint: 1025,
              settings: {
                centerMode: true,
                slidesToShow: 3,
              },
            },
            {
              breakpoint: 767,
              settings: {
                centerMode: false,
                slidesToShow: 1,
              },
            },
        ],
    };

    return(
        <div>
            {/* Conditional styling based on current slide */}
            {currentActiveSlide === 0 && <style>{`.active0 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 1 && <style>{`.active1 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 2 && <style>{`.active2 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 3 && <style>{`.active3 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 4 && <style>{`.active4 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 5 && <style>{`.active5 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 6 && <style>{`.active6 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 7 && <style>{`.active7 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 8 && <style>{`.active8 { font-weight: bold; }`}</style>}
            {currentActiveSlide === 9 && <style>{`.active9 { font-weight: bold; }`}</style>}

            {currentBaySlide === 0 && <style>{`.bay0 { font-weight: bold; }`}</style>}
            {currentBaySlide === 1 && <style>{`.bay1 { font-weight: bold; }`}</style>}
            {currentBaySlide === 2 && <style>{`.bay2 { font-weight: bold; }`}</style>}
            {currentBaySlide === 3 && <style>{`.bay3 { font-weight: bold; }`}</style>}
            {currentBaySlide === 4 && <style>{`.bay4 { font-weight: bold; }`}</style>}
            {currentBaySlide === 5 && <style>{`.bay5 { font-weight: bold; }`}</style>}
            {currentBaySlide === 6 && <style>{`.bay6 { font-weight: bold; }`}</style>}
            {currentBaySlide === 7 && <style>{`.bay7 { font-weight: bold; }`}</style>}
            {currentBaySlide === 8 && <style>{`.bay8 { font-weight: bold; }`}</style>}
            {currentBaySlide === 9 && <style>{`.bay9 { font-weight: bold; }`}</style>}
            {pageData ? (
                <>
                    <Banner head={pageData.banner.left_heading}
                    rightText={<span dangerouslySetInnerHTML={{ __html: pageData.banner.right_text }} />}
                    backgroundImage={pageData.banner.background_image} bgColor="bg-[#e6e7e8]" headColor="text-darkGreen" textColor="text-black" 
                    headWidth="w-[100%] larger:w-[16.5%] xxl:w-[17%] 2xl:w-[17%] xxxl:w-[14%] medium:w-[19.97%]"
                    secWidth="w-[90%] xxxl:w-[64%] larger:w-[65.5%] xxl:w-[70%] xl:w-[75%] medium:w-[80%]"
                    />

                    {/* ACTIV Health Club */}
                    <section className="py-4 md:py-20 xxl:pt-20 seamSec bg-white">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                            <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                                {pageData.activ_health_club.heading}
                            </h2>
                            <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen text-center xl:mt-5 mx-auto">
                                <span dangerouslySetInnerHTML={{ __html: pageData.activ_health_club.sub_heading }} />
                            </p>
                            {/* <p>Current Slide: {currentActiveSlide}</p> */}
                            {pageData.activ_health_club.text ? (
                                <>
                                    {pageData.activ_health_club.text.length > 0 ? (
                                        <div className="flex justify-center flex-wrap items-center mt-5 gap-y-4">
                                        {pageData.activ_health_club.text.map((slide, index) => (
                                            <p className={`${index > 0 ? ' border-l' : ''} font-AvenirLight font-light text-xs md:text-sm leading-normal px-2 md:px-4 text-center xl:mt-5 active${index}`} key={index}>
                                                {slide.title}
                                            </p>
                                        ))}
                                    </div>
                                    ) : (<></>)}
                                </>
                            ) : (<></>)}

                            {/* slider */}    
                            {pageData.activ_health_club.slider ? (
                                <div className="picture-slider mb-[12%] health-slider">
                                {pageData.activ_health_club.slider.length > 0 ? (
                                    <Slider {...sliderPicture}>
                                    {pageData.activ_health_club.slider.map((slide, index) => (
                                        <Link to={slide.image_link} onClick={() => { window.scroll(0, 0); }} className="item" key={index}>
                                            <div className="content">
                                            <img className='object-top 2xl:h-[450px] md:h-[350px] h-[250px]' src={slide.image} alt="" />
                                            </div>
                                        </Link>
                                    ))}
                                    </Slider>
                                ) : (<></>)}
                                </div>
                            ) : (<></>)}
                        </div>
                    </section>

                    {/* The Bay */}
                    <section className="py-8  md:py-[10%] xxl:pt-28 larger:pb-32 relative vid bg-white">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                            <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize">
                                {pageData.the_bay.heading}
                            </h2>
                            <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white text-center xl:mt-5">
                                <span dangerouslySetInnerHTML={{ __html: pageData.the_bay.sub_heading }} />
                            </p>


                            {pageData.the_bay.text ? (
                                <>
                                    {pageData.the_bay.text.length > 0 ? (
                                        <div className="flex justify-center flex-wrap items-center mt-5 gap-y-4">
                                            {pageData.the_bay.text.map((slide, index) => (
                                                <p className={`${index > 0 ? ' border-l' : ''} font-AvenirLight font-light text-xs md:text-sm leading-normal px-2 md:px-4 text-white text-center xl:mt-5 bay${index}`} key={index}>
                                                    {slide.title}
                                                </p>
                                            ))}
                                        </div>
                                    ) : (<></>)}
                                </>
                            ) : (<></>)}

                            {/* slider */}  
                            {pageData.the_bay.slider ? (
                                <div className="picture-slider bay-slider">
                                {pageData.the_bay.slider.length > 0 ? (
                                    <Slider {...baysliderPicture}>
                                    {pageData.the_bay.slider.map((slide, index) => (
                                        <div className="item" key={index}>
                                            <div className="content">
                                            <img className='object-cover 2xl:h-[450px] md:h-[350px] h-[250px]' src={slide.image} alt="" />
                                            </div>
                                        </div>
                                    ))}
                                    </Slider>
                                ) : (<></>)}
                                </div>
                            ) : (<></>)}
                            
                        </div>

                        <div className="before:absolute before:top-0 before:left-0 before:right-0 before:h-[68%] before:bg-fadeGreen before:z-[-1]"></div>
                    </section>

                    {/* Amphitheatre */}
                    <section className="py-4 md:py-14 md:pt-[10%] xxl:pt-20 xxl:pb-40 vid bg-white">
                        <div className="container xxl:max-larger:max-w-[1220px]">
                        <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                            {pageData.amphitheatre.heading}
                        </h2>
                        <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen text-center xl:mt-5 xl:w-3/4 mx-auto">
                            <span dangerouslySetInnerHTML={{ __html: pageData.amphitheatre.text }} />
                        </p>

                        <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 py-2 md:py-4 xl:py-6 bg-white mt-4 lg:mt-24">
                            <img
                            src={pageData.amphitheatre.image}
                            alt="theatre-crowded"
                            className="w-full object-contain"
                            />
                        </div>
                        </div>
                    </section>
                </>
            ) : (<></>)}

            <Footer />
        </div>
    )
}

export default BeyondWork
