import React, { useEffect } from "react";
import './App.css';

import AOS from "aos";
import "aos/dist/aos.css";

import HomePage from "./pages/home";
import TechForward from './pages/techForward';
import CampusPage from './pages/campusPage';
import AboutUs from './pages/aboutUs';
import InspirationalSpaces from './pages/inspirationalSpaces';
import Testimonial from './pages/testimonial';
import Sustainability from './pages/sustainability';
import PrivacyPolicy from './pages/privacyPolicy';
import TermsCondition from './pages/termsConditions';
import GetInTouch from './pages/getInTouch';
import ThankYou from './pages/thankYou'
import MediaCentre from './pages/mediaCentre';
import Events from './pages/events';
import BeyondWork from './pages/beyondWork';
import SingleEvent from './pages/singleEvent';
import ConnectivityPage from "./pages/connectivityPage";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

export default function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    
  <div className="">
     <Router>
      <Routes>
        <Route exact path="/" element={<HomePage/>} />
        <Route exact path="/connectivity" element={<ConnectivityPage/>} />
        <Route exact path="/campus" element={<CampusPage />} />
        <Route exact path="/technology" element={<TechForward />} />
        <Route exact path="/about-brookfield" element={<AboutUs />} />
        <Route exact path="/inspirational-spaces" element={<InspirationalSpaces />} />
        <Route exact path="/testimonial" element={<Testimonial />} />
        <Route exact path="/sustainability-wellness" element={< Sustainability />} />
        <Route exact path="/privacy-policy" element={< PrivacyPolicy /> } />
        <Route exact path="/terms-and-conditions" element={< TermsCondition />} />
        <Route exact path="/get-in-touch" element={< GetInTouch />} />
        <Route exact path="/thank-you" element={< ThankYou />} />
        <Route exact path="/media-centre" element={< MediaCentre />} />
        <Route exact path="/events" element={< Events />} />
        <Route exact path="/single-event/:id" element={< SingleEvent />} />
        <Route exact path="/beyond-work" element={< BeyondWork />} />
      </Routes>
    </Router>
  </div>
  )
};
