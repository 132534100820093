import React, { useEffect, useState } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Animate = () => {
  // Fetch Section Data
  const [SecOneData, setSecOneData] = useState(null);
  useEffect(() => {
    const fetchSecOneData = async () => {
      try {
        const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/6');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setSecOneData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchSecOneData();
  }, []);
  // End Fetch Section Data
  
  return (
    // Home page second section
    <section className="section bg-[#354033] mt-[-2px]">
      <div className="container xxl:max-larger:max-w-[1220px] mx-auto xxl:w-[78%] 2xl:w-[81%] xxxl:w-[73%] xl:w-[82%] medium:w-[86%]">
        {SecOneData ? (
          <div className="flex justify-between place-items-center column-area">
            <div className='content'>
              <p className='text-white text-[12pt] md:text-nm text-left md:text-left exp-text-one fixed opacity-0 w-[100%] xxl:w-[20%] 2xl:w-[21%] xxxl:w-[18.5%] medium:w-[21%] font-SangBleuKingdomTrial'>
                <span dangerouslySetInnerHTML={{ __html: SecOneData.acf.creating_world_class.left_text}} />
              </p>
            </div>
            <div className='content self-center'>
              <p className=' text-white text-[12pt] text-left md:text-left ms-auto exp-text-two fixed top-[68%] right-[2%] xxxl:right-[8%] 2xl:right-[7%] xl:right-[3%] md:right-[2%] opacity-0 2xl:w-[24%] w-[97%] xl:w-[28%] lg:w-[32%] md:w-[42%] font-AvenirLight'>
                <span dangerouslySetInnerHTML={{ __html: SecOneData.acf.creating_world_class.right_text}} />
              </p>
            </div>
          </div>
        ) : ('')}
        {/* Scrolling height from second to third section */}
        <div className='min-h-screen ipad'></div>
        <div className='min-h-screen'></div>
        <div className='min-h-screen'></div>
        <div className='min-h-screen'></div>
        {/* End of Scrolling height from second to third section */}
      </div>
    </section> 
    // End Home page second section    
  )
}

export default Animate
