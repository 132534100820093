import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Campus = () => {
  // at the top video
  const [isPlaying, setIsPlaying] = useState(false);
  const handleVideoClick = (videoElement) => {
    const overlayOn = document.querySelector(".overlay-on");
    if (videoElement.paused || videoElement.ended) {
      videoElement.play();
      setIsPlaying(true);
      overlayOn.classList.remove("bg-[#000000b2]");
    } else {
      videoElement.pause();
      setIsPlaying(false);
      overlayOn.classList.add("bg-[#000000b2]");
    }
  };

  // slider A Look at Ecoworld
  const sliderlook = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 576,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  // Accessibility slider
  const sliderAccessibility = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          centerMode: false,
          slidesToShow: 3,
        },
      },

      {
        breakpoint: 1025,
        settings: {
          centerMode: false,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  // Picture slider
  const sliderPicture = {
    dots: false,
    arrows: true,
    infinite: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 500,
    cssEase: "linear",
    centerMode: true,
    centerPadding: "0px",
    responsive: [
      {
        breakpoint: 1025,
        settings: {
          centerMode: true,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          centerMode: false,
          slidesToShow: 1,
        },
      },
    ],
  };

  // Fetch Page Data
  const [pageData, setpageData] = useState(null);
  useEffect(() => {
      const fetchpageData = async () => {
      try {
          const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/238');
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setpageData(data.acf);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      };

      fetchpageData();
  }, []);

  return (
    <>
      {pageData ? (
        <>
          {/* tour around the campus */}
          <section className="py-10 md:py-14 lg:py-24 vid bg-white seamSec">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <div className="contained border-2 border-lightGray p-2 md:p-6 lg:p-10">
                <div className="relative">
                  <video
                    loop poster={pageData.video.video_thumbnail}
                    className="video-played w-full max-h-[600px] larger:max-h-[calc(100vh-276px)] object-cover border border-lightGray"
                  >
                    <source src={pageData.video.url} />
                  </video>

                  <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center overlay-on">
                    <img
                      src={
                        isPlaying
                          ? "/images/light-pause-icon.webp"
                          : "/images/light-play-icon.webp"
                      }
                      alt="light-play-icon"
                      className="circle-play w-full max-w-[40px] md:max-w-[80px] cursor-pointer object-contain duration-300"
                      onClick={() =>
                        handleVideoClick(document.querySelector(".video-played"))
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* Masterplan */}
          <section className="pb-8 md:py-10 lg:py-20 xxl:pt-28 larger:pb-32 relative vid bg-white" id="Masterplan">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                {pageData.masterplan.heading}
              </h2>
              <div className="mt-4 text-center">
                <h4 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal">
                  <span dangerouslySetInnerHTML={{ __html: pageData.masterplan.sub_heading }} />
                </h4>
                <p className="font-AvenirLight font-light text-xs xl:text-[16pt] larger:text-sm leading-normal text-fadeGreen md:mt-2">
                  <span dangerouslySetInnerHTML={{ __html: pageData.masterplan.text }} />
                </p>
              </div>
              <div>
                <img
                  src={pageData.masterplan.image}
                  alt="campus-3d-map"
                  className="w-full object-contain"
                />
              </div>
              
            </div>
          </section>

          {/* A Look at Ecoworld */}
          <section className="py-8  md:py-20 xxl:pt-28 larger:pb-32 relative vid bg-white">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize">
                {pageData.look_at_ecoworld.heading}
              </h2>
              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white text-center xl:mt-5">
                <span dangerouslySetInnerHTML={{ __html: pageData.look_at_ecoworld.sub_heading }} />
              </p>

              {/* look at ecoworld slider */}
              {pageData.look_at_ecoworld.look_slider ? (
              <div className="lookecoworld mx-[-12px]">

                {pageData.look_at_ecoworld.look_slider.length > 0 ? (
                  <Slider
                    {...sliderlook}
                    className="mt-10 lg:mt-20 larger:mt-28 h-full"
                  >
                    {pageData.look_at_ecoworld.look_slider.map((slide, index) => (
                      <div className="px-3 h-full" key={index}>
                        <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 pt-2 md:pt-4 xl:pt-6 bg-white">
                          <div className="relative">
                            <img
                              src={slide.image}
                              alt="light-logo"
                              className="w-full lg:h-[430px] md:h-[330px] h-[280px] object-cover object-top"
                            />

                            <div className="px-4 py-4 md:py-8 lg:py-12">
                              <h2 className="font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-[20pt] larger:text-lg font-medium text-fadeGreen leading-normal capitalize">
                                {slide.heading}
                              </h2>
                              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen xl:mt-2">
                                <span dangerouslySetInnerHTML={{ __html: slide.sub_heading }} />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (<></>)}

               
              </div>
              ) : (<></>)}
            </div>

            <div className="before:absolute before:top-0 before:left-0 before:right-0 before:h-[400px] md:before:h-[600px] before:bg-fadeGreen before:z-[-1]"></div>
          </section>

          {/* Workspace Solutions */}
          <section className="mt-[-2px] py-4 md:py-[10%] xxl:pt-28 md:pb-28 larger:pb-32 md:max-xl:pt-10 vid bg-white">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                {pageData.workspace_solutions.heading}
              </h2>
              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen text-center xl:mt-5 xl:max-w-[96%] larger:max-w-[72%] mx-auto">
                <span dangerouslySetInnerHTML={{ __html: pageData.workspace_solutions.sub_heading }} />
              </p>


              <div className="workspace-slider mx-[-12px]">
                {/* slider */}
                {pageData.workspace_solutions.workspace_solutions_slider ? (
                <div className="picture-slider campus-slider md:pt-10">
                    {pageData.workspace_solutions.workspace_solutions_slider.length > 0 ? (
                      <Slider {...sliderPicture}>

                        {pageData.workspace_solutions.workspace_solutions_slider.map((slide, index) => (
                          <div className="item" key={index}>
                              <div className="content text-left">
                              <img src={slide.image} alt="" className='object-cover 2xl:h-[380px] lg:h-[280px] md:h-[280px] h-[210px]' />
                              {/* <div className='h-[400px] w-[400px] bgImg' style={{ backgroundImage: `url(${slide.image})` }}></div> */}
                              <div className="text md:pt-10 pt-5 px-3 md-px-0">
                                <h4 className="font-SangBleuKingdomTrial text-darkGreen text-xs md:text-sm font-bold">{slide.heading}</h4>
                                <p>
                                <span dangerouslySetInnerHTML={{ __html: slide.sub_heading }} />
                                </p>
                              </div>
                              </div>
                          </div>
                        ))}
                      </Slider>
                    ) : (<></>)}
                </div>
                ) : (<></>)}
              </div>
            </div>
          </section>

          {/* Accessibility */}
          <section className="mt-[-2px] py-4 md:py-10 xxl:pt-28 md:pb-28 larger:pb-32 md:max-xl:pt-10 vid bg-white" id="Accessibility">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                {pageData.accessibility.heading}
              </h2>
              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen text-center xl:mt-5 xl:max-w-[96%] larger:max-w-[72%] mx-auto">
                <span dangerouslySetInnerHTML={{ __html: pageData.accessibility.sub_heading }} />
              </p>

              {/* Slider */}
              {pageData.accessibility.accessibility_slider ? (
              <div className="Accessibility-slider mx-[-12px]">
                {pageData.accessibility.accessibility_slider.length > 0 ? (
                  <Slider
                    {...sliderAccessibility}
                    className="mt-4 lg:mt-20 larger:mt-28"
                  >
                    {pageData.accessibility.accessibility_slider.map((slide, index) => (
                      <div className="px-3 h-full" key={index}>
                        <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 pt-2 md:pt-4 xl:pt-6 bg-white">
                          <div className="relative">
                            <img
                              src={slide.image}
                              alt="light-logo"
                              className="w-full object-cover md:h-[400px] h-[350px]"
                            />

                            <div className="px-4 py-4 md:py-8 lg:py-12">
                              <h2 className="font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-[20pt] larger:text-lg font-medium text-fadeGreen leading-normal capitalize">
                                {slide.heading}
                              </h2>
                              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen xl:mt-2">
                                <span dangerouslySetInnerHTML={{ __html: slide.sub_heading }} />
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (<></>)}
              </div>
              ) : (<></>)}


            </div>
          </section>


          {/* Sustainability */}
          <section className="mt-[-2px] py-4 md:py-14 xxl:py-20 larger:py-24 vid bg-white">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                {pageData.sustainability.heading}
              </h2>
              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen text-center xl:mt-5 xl:max-w-[96%] larger:max-w-[72%] mx-auto">
                <span dangerouslySetInnerHTML={{ __html: pageData.sustainability.sub_heading }} />
              </p>

              <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 py-2 md:py-4 xl:py-6 bg-white mt-4 lg:mt-24">
                <img
                  src={pageData.sustainability.image}
                  alt="Sustainability-item-1"
                  className="w-full object-contain"
                />
              </div>

              <div className="text-center mt-4 lg:mt-10">
                <a
                  href={pageData.sustainability.link.url}
                  className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen inline-block"
                >
                  {pageData.sustainability.link.title}
                </a>
              </div>
            </div>
          </section>

          {/* Ratings & Certifications */}
          <section className="mt-[-2px] py-8 md:py-14 xxl:pt-6 xxl:pb-24 larger:pt-20 larger:pb-44 vid bg-white">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-fadeGreen leading-normal capitalize">
                {pageData.ratings_and_certifications.heading}
              </h2>

              <div className="grid grid-cols-1 lg:grid-cols-2 items-center gap-14 mt-4 md:mt-20 lg:mt-20 larger:mt-24">
                {pageData.ratings_and_certifications.left_area ? (
                  <>
                    {pageData.ratings_and_certifications.left_area.length > 0 ? (
                    <div className="grid grid-cols-4 items-center gap-10">
                      {pageData.ratings_and_certifications.left_area.map((slide, index) => (
                        <img key={index}
                          src={slide.image}
                          alt="light-logo"
                          className="w-full max-w-[240px] max-h-[90px] object-contain"
                        />
                      ))}
                    </div>
                    ):(<></>)}
                  </>
                ): (<></>)}

                <div className="border-2 border-lightGray p-4 md:p-8 lg:p-14">
                  {pageData.ratings_and_certifications.right_area ? (
                    <>
                      {pageData.ratings_and_certifications.right_area.length > 0 ? (
                      <>
                        {pageData.ratings_and_certifications.right_area.map((slide, index) => (
                         <div className="flex items-baseline gap-2 sm:gap-4 duration-200 p-2 larger:p-4" key={index}>
                            <div className="border border-black p-[2px] md:p-1 rounded-full">
                              <span className="bg-black w-2 md:w-3 h-2 md:h-3 block rounded-full"></span>
                            </div>
        
                            <h5 className="font-AvenirLight text-xs md:text-[16pt] text-lightGray leading-normal relative top-[-4px]">
                              {slide.title}
                            </h5>
                          </div>
                        ))}
                      </>
                      ):(<></>)}
                    </>
                  ): (<></>)}
                </div>

              </div>
            </div>
          </section>

           {/* Current tenants at Ecoworld */}
          <section className="py-8 md:py-20 xl:py-40 bg-[#595a5c] vid">
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="md:text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize">
                {pageData.tenants_at_ecoworld.heading}
              </h2>
              <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-white md:text-center mt-8">
                <span dangerouslySetInnerHTML={{ __html: pageData.tenants_at_ecoworld.description }} />
              </p>

              {pageData.tenants_at_ecoworld.links ? (
              <div className="md:flex justify-center items-center gap-3 mt-10 max">

                {pageData.tenants_at_ecoworld.links.length ? (
                  <>
                    {pageData.tenants_at_ecoworld.links.map((linkname, index) => (
                     <span key={index}
                     className={`${index > 0 ? 'border-l-[3px] pl-5' : 'max-md:border-l-[3px] max-md:pl-5'} font-SangBleuKingdomTrial font-light text-xs md:text-sm leading-normal text-white mt-5 block`}
                   >
                     {linkname.title.title}
                   </span>
                    ))}
                  </>
                ) : (<></>)}

              </div>
              ) : (<></>)}

            </div>
          </section>

           {/* feedbacks or testimonials */}
           {/* <section className="pt-14 pb-10 larger:py-44 vid bg-white">
            <div className="container xl:px-24 pb-10 md:pb-24">

            {pageData.testimonials.slide ? (
              <div className="slider-feed mx-[-12px]">
                {pageData.testimonials.slide.length > 0 ? (
                  <Slider {...sliderFeed}>
                    {pageData.testimonials.slide.map((slide, index) => (
                      <div className="px-3 h-full" key={index}>
                        <div className="grid lg:grid-cols-2 items-center gap-14">
                          <div className="border-2 border-lightGray p-2 md:p-4">
                            <div className="containedfeed relative">
                              <video
                                loop poster={slide.video_thumbnail}
                                className={`video-played-feed-${index} w-full h-[340px] object-cover border border-lightGray`}
                              >
                                <source src={slide.video_url} />
                              </video>

                              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full flex justify-center items-center overlay-on-feed">
                                <img
                                  src={
                                    feedPlaying
                                      ? "/images/light-pause-icon.webp"
                                      : "/images/light-play-icon.webp"
                                  }
                                  alt="light-play-icon"
                                  className="circle-play w-full max-w-[40px] md:max-w-[80px] cursor-pointer object-contain duration-300"
                                  onClick={() =>
                                    handleClickfeed(
                                      document.querySelector(
                                        `.video-played-feed-${index}`
                                      )
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div>
                            <img
                              src="/images/revert-inverted-comma.png"
                              alt="inverted commaa"
                              className="w-full max-w-[40px] md:max-w-[60px] object-contain"
                            />

                            <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen mt-10 lg:mt-4 xxl:mt-14">
                              <span dangerouslySetInnerHTML={{ __html: slide.text }} />
                            </p>

                            <div className="flex items-start gap-3 mt-10 lg:mt-4 xxl:mt-14">
                              <div className="font-AvenirLight text-xs md:text-sm font-bold text-fadeGreen leading-2 capitalize">
                                _
                              </div>
                              <div>
                                <h2 className="font-AvenirLight text-xs md:text-sm font-bold text-fadeGreen leading-normal capitalize">
                                  {slide.user}
                                </h2>
                                <p className="font-AvenirLight font-light text-xs md:text-sm leading-normal text-fadeGreen">
                                  {slide.user_position}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (<></>)}
              </div>
            ) : (<></>)}
            </div>
          </section> */}

        </>
      ) : ('')}
      
    </>
  );
};

export default Campus;
