import React, { useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Header from '../components/header';
import Footer from "../components/footer";

const MediaCentre = () => {

  window.addEventListener('scroll', function () {
    const header = document.querySelector('header');
    if(header){
      header.style.backgroundColor = "#324031";
    }
  })

  // Fetch Page Data
  const [pageData, setpageData] = useState(null);
  useEffect(() => {
      const fetchpageData = async () => {
      try {
          const response = await fetch('https://ecoworld.geek-tasks.com/backend/wp-json/wp/v2/pages/549');
          if (!response.ok) {
          throw new Error('Network response was not ok');
          }

          const data = await response.json();
          setpageData(data.acf);
      } catch (error) {
          console.error('Error fetching data:', error);
      }
      };

      fetchpageData();
  }, []);

  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>

      {/* Header */}
      <header className="fixed inset-x-0 top-0 z-50 bg-darkGreen py-3">
        <Header />
      </header>

      {/* get all your queries answered. */}
      {pageData ? (
        <Tabs
          selectedIndex={selectedTab}
          onSelect={(index) => setSelectedTab(index)}
        >
          <section className="pt-8 md:pt-14 xl:pt-28 bg-fadeGreen">
            <div className='container xxl:max-larger:max-w-[1220px] pt-[4rem] pb-[3rem] md:pb-[0rem] md:pt-20'>
              <a href="/" className="font-AvenirLight leading-normal text-white inline-flex items-center ">
                <svg
                  fill="white"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 448 512">
                  <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
                </svg>
                <span className="ml-4">Explore Ecoworld</span>
              </a>
            </div>
           
            <div className="container xxl:max-larger:max-w-[1220px]">
              <h2 className="text-center font-SangBleuKingdomTrial max-[300px]:text-[16pt] text-sm md:text-xxl font-medium text-white leading-normal capitalize mt-10 md:mt-20 xl:mt-28">
                {pageData.heading}
              </h2>

              {pageData.events.category ? (
                <>
                  {pageData.events.category.length > 0 ? (
                    <TabList className="flex justify-center mt-4 md:mt-10 xl:mt-20">
                      {pageData.events.category.map((category, index) => (
                         <Tab className="font-SangBleuKingdomTrial text-[14pt sm] md:text-sm font-medium text-gray-400 leading-normal capitalize cursor-pointer px-4 md:px-10 xl:px-20 py-2 md:py-4 xl:py-6 outline-none focus-visible:border border rounded-none" key={index}>
                          {category.name}
                        </Tab>
                      ))}
                    </TabList>
                  ) : (<></>)}
                </>
              ) : (<></>)}

            </div>
          </section>
  
          {pageData.events.category ? (
            <section className="py-4 md:py-14 xl:py-24">
              {pageData.events.category.length > 0 ? (
                <div className="container xxl:max-larger:max-w-[1220px]">
                  {pageData.events.category.map((category, index) => (
                    <TabPanel key={index}>
                      <div className="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-4 md:gap-x-6 md:gap-y-12">
                        {category.slider ? (
                          <>
                            {category.slider.length > 0 ? (
                              <>
                                {category.slider.map((slide, index) => (
                                  <div className="h-full" key={index}>
                                    <div className="border-2 border-lightGray px-2 md:px-4 xl:px-6 pt-2 md:pt-4 xl:pt-6 bg-white">
                                      <div className="relative">
                                        <img
                                          src={slide.image}
                                          alt="media-center-items"
                                          className="w-full object-contain"
                                        />
                  
                                        <div className="px-4 py-4 md:py-8 lg:py-12">
                                          <p className="font-AvenirLight font-light text-xs leading-normal text-gray-700">
                                            {category.name}
                                          </p>
                                          <h2 className="font-SangBleuKingdomTrial text-xs xl:text-sm font-medium text-fadeGreen leading-normal capitalize mt-2">
                                            <span dangerouslySetInnerHTML={{ __html: slide.title }} />
                                          </h2>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </>
                            ) : (<></>)}
                          </>
                        ) : (<></>)}
                        

                      </div>
                    </TabPanel>
                  ))}
                </div>
              ) : (<></>)}
            </section>
          ) : (<></>)}
        </Tabs>
      ) : (<></>)}
    
      {/* Footer */}
      <Footer />
    </div>
  );
};

export default MediaCentre;
